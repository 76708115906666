import Cookie from 'js-cookie';

let widgetIdBase = '';
let widgetUrlBase = '';
let widgetTokenBase = '';
let userClientToken = '';
let agentsList;
let agent_selected = null;
const client_contact = { 'name' : null, 'email' : null, 'phone' : null };
let statusRegisterHistory = false;
let progressRegisterHistory = 0;
let languagePage = 'en';
let messageWidgetAgent = '';
let inputUser = '';
let showListAgents = false;
let activeVoice = 0;
let customerContactCycleCampaingMarketing = null; // Id de una campaña de marketing.
let initialMessageFromExtWidget = { message: '', voice: '' }; // Mensaje inicial de un agente en el chat, iniciado por un evento de otro widget.
// let colorBackgroundButton = '';
const urlImagePlaceHolder = "assets/images/placeholder.jpg";

let timerToRemoveWaitingMessage = null;

const filterElementsFromMessages = require('../../dashboard/chatgpt/filterElementsFromMessageWithRequired');
const ttsNavigator = require( './ttsNavigator' );
const ttsVoiceChatbot = require('./ttsVoiceChatbot');
const recordAudioMic = require( '../../services/api/recordAudioMic' );


// Función inicial al inicializar el script.
window.addEventListener('DOMContentLoaded', initWidgetAgents );

/**
 * Función incial del script, verifica y prepara todos los elementos de widget,
 * realiza un fetch para obtener la información a presentar en el Widget.
 */
function initWidgetAgents () {

    getFirstActionFromUserForEnableAudioInVideoBackground();

    scrollChatToBotton();

    getIdWidgetAgents();

    getURlWidgetAgent();
    
    getTokenWidgetAgent();

    initModalImgWA();
    
    initGetValueCookieForUniqueUser();
    
    getUrlParamsForWidgetAgents();
    
    getAgentsFromBusswe();
}

function initBasicElementsInWidget()
{
    const buttonsSubmit = [ 
        document.getElementById('dialog-widget-area-actions-submit'), 
        document.querySelector('#busswe-widget #dialog-widget-2 .container-options-input button.button-submit-message-chat')
    ];

    for (let index = 0; index < buttonsSubmit.length; index++) {
        const elementButton = buttonsSubmit[index];
        
        elementButton.addEventListener('click', () => { submitMessageToHistory(); });
    }



    const buttonsBackToListAgents = [
        document.getElementById('dialog-widget-area-button-back').querySelector('svg'),
        document.querySelector('#busswe-widget #dialog-widget-2 .container-main-chat .header-main-chat .group-options-1 .back-to-list-agents')
    ];

    for (let index = 0; index < buttonsBackToListAgents.length; index++) {
        const elementButtonBack = buttonsBackToListAgents[index];
        
        if( elementButtonBack )
        elementButtonBack.addEventListener('click', () => {
            hideHistoryMessage();
            ttsVoiceChatbot.cancelAllTracksInQueue();

            muteAudioVideoBackgroundWidget2( false );
        });
    }


    
    const buttonShowListAgent = document.getElementById('dialog-widget-agents-button-show-list');
    if( buttonShowListAgent && buttonShowListAgent.querySelector('button') )
    {
        const buttonShowListAction = buttonShowListAgent.querySelector('button');
        const nameClassHightlight = 'hightlight_button_to_list_agents';

        buttonShowListAction.addEventListener('click', () => {
            toggleShowListAgents();

            if( buttonShowListAgent.classList.contains( nameClassHightlight ) )
            buttonShowListAgent.classList.remove( nameClassHightlight );
        });

        buttonShowListAgent.classList.add( nameClassHightlight );

        const cotainerButtonHightlight = document.createElement( 'div' );
        cotainerButtonHightlight.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-index-fill" viewBox="0 0 16 16"><path d="M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v5.34l-1.2.24a1.5 1.5 0 0 0-1.196 1.636l.345 3.106a2.5 2.5 0 0 0 .405 1.11l1.433 2.15A1.5 1.5 0 0 0 6.035 16h6.385a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5 5 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.6 2.6 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046z"/></svg>';

        buttonShowListAgent.appendChild( cotainerButtonHightlight );
    }
    

    const buttonsShowListAgent2 = document.querySelectorAll('#busswe-widget #dialog-widget-2 .container-content-widget .show-chat-button');
    if( buttonsShowListAgent2 && buttonsShowListAgent2.length > 0 )
    {
        for (let index = 0; index < buttonsShowListAgent2.length; index++) {
            const elementButtonShow = buttonsShowListAgent2[index];
            
            elementButtonShow.addEventListener('click', () => {

                if( !boolUseDesign2 || !agent_selected ) return;

                // toggleShowListAgents();
                showHistoryMessage();

            });
        }

    }



    const textareasList = [
        document.getElementById('dialog-widget-area-actions-textarea'),
        document.querySelector('#busswe-widget #dialog-widget-2 .container-options-input textarea')
    ];

    for (let index = 0; index < textareasList.length; index++) {
        const elementTextArea = textareasList[index];
        
        if( elementTextArea )
        {
            elementTextArea.value = '';
            elementTextArea.addEventListener('keypress', function (event) {
                if( event.key === 'Enter' ){
                    event.preventDefault();
                    submitMessageToHistory();
                }
            });
        }
    }
}

/**
 * Verifica el idioma que tiene el widget, obtiene la información del html del widget.
 */
function checkLanguageFromPage()
{
    const inputLang = document.getElementById('dialog-widget-lang');

    if( inputLang )
    {
        languagePage = inputLang.value == 'es' ? 'es' : 'en';
    }
}

/**
 * Ubica el scroll al final del chat, en el Widget agentes.
 */
function scrollChatToBotton()
{
    const viewChartWidget = boolUseDesign2 ? containerMessages2 : document.getElementById('dialog-widget-area-history');

    if( viewChartWidget )
    viewChartWidget.scrollTop = viewChartWidget.scrollHeight;
}

/**
 * Alterna entre las vistas Lista de Agentes y Chat con Agente.
 */
function toggleShowListAgents()
{
    if( boolUseDesign2 )
    {
        showListAgents = !showListAgents;

        const widget2 = document.querySelector('#busswe-widget #dialog-widget-2 .container-content-widget');

        if( widget2 )
        widget2.classList.toggle( 'show-chat', showListAgents );
    }
    else
    {
        const widget = document.getElementById('busswe-widget');
    
        if( !widget ) return;
        
        const button = widget.querySelector('#dialog-widget-agents-button-show-list');
        const listAgents = widget.querySelector('#dialog-widget-agents-list');
        const banner = widget.querySelector('#dialog-widget-agents-image');
        const containerWidget = widget.querySelector('#dialog-widget');
    
        if( button && listAgents )
        {
            if( showListAgents )
            {
                banner.classList.remove('show-list-agent');
                button.classList.remove('show-list-agent');
                listAgents.classList.remove('show-list-agent');
                showListAgents = false;
            }
            else
            {
                if( containerWidget && containerWidget.classList.contains('widgetWithoutBanner') )
                banner.classList.add('show-list-agent');
                
                button.classList.add('show-list-agent');
                listAgents.classList.add('show-list-agent');
                showListAgents = true;
            }
        }
    }
}

/**
 * Obtiene del DOM del contenedor del Widget Agentes.
 * @returns 
 */
function getWidgetContainer()
{
    return document.querySelector("#busswe-widget.widget-agent-init");
}

function getIdWidgetAgents() {
    const widgetContainer = getWidgetContainer();

    if( !widgetContainer ) return;

    const widgetId = widgetContainer.querySelector('#widget-agents-id');

    if (widgetId && (widgetId.value != 'id' || widgetId.value != '')) {
        widgetIdBase = widgetId.value;
        widgetId.remove();

        const emitEventToGetWidgetAgentId = () => {
            window.document.dispatchEvent( 
                new CustomEvent('receiveIdFromWidgetAgent', { bubbles: true, detail: { widgetId: widgetIdBase } })
            );
        };

        window.document.addEventListener( "getIdFromWidgetAgents", () => {
            emitEventToGetWidgetAgentId();
        } );

        emitEventToGetWidgetAgentId();
    }
}

/**
 * Obtiene el html widget, el URL base para los fetch.
 */
function getURlWidgetAgent()
{
    const widgetContainer = getWidgetContainer();

    if( !widgetContainer ) return;

    // const url = document.getElementById('dialog-widget-url');
    const url = widgetContainer.querySelector('#dialog-widget-url');
    
    if( url && (url.value != 'url' || url.value != '') )
    {
        widgetUrlBase = url.value;
        url.remove();
    }
}

/**
 * Obtiene los paramentros del url, para almacenarlos para las siguientes requests.
 */
function getUrlParamsForWidgetAgents()
{
    const urlParamsString = window.location.search;

    const urlParams = new URLSearchParams( urlParamsString );

    customerContactCycleCampaingMarketing = urlParams.get( "contactWithCycleCampaignIdWidgetAgent" );
}

/**
 * Obtiene el html widget, el Token base para los fetch.
 */
function getTokenWidgetAgent()
{
    const widgetContainer = getWidgetContainer();

    if( !widgetContainer ) return;

    // const token = document.getElementById('dialog-widget-token');
    const token = widgetContainer.querySelector('#dialog-widget-token');

    if( token && (token.value != 'token' || token.value != '') )
    {
        widgetTokenBase = token.value;
        token.remove();
    }
}

/**
 * Consulta por medio de un Fetch los agentes disponibles del usuario en el Widget.
 */
async function getAgentsFromBusswe()
{
    const url = widgetUrlBase + 'v1/widget/agents/info';

    const data = userClientToken ? { cifwa: userClientToken } : {};

    if( customerContactCycleCampaingMarketing )
    data.ccccm = customerContactCycleCampaingMarketing;

    if( widgetIdBase != '' )
        data.widget_id = widgetIdBase;

    const fetchWidgetBase = await fetchAgentsWidget( 'GET', url, data );

    // console.log('Resultado del fetch ', fetchWidgetBase);

    if( fetchWidgetBase.status ){
        // console.log('Paso el fetch', fetchWidgetBase.result);
        initializeWidget( fetchWidgetBase.result );
    }
    else
    console.error('Fetch Failed');
}

/**
 * Fetch bade para las consultas del widget.
 * @param {*} method 
 * @param {*} url 
 * @param {*} data 
 * @returns 
 */
async function fetchAgentsWidget ( method = 'GET', url, data = {} )
{
    let options = {
        method: method,
        mode: 'cors',
        headers: {
            accept: 'application/json',
            'Access-Control-Allow-Origin':'*',
            'Authorization' : 'Bearer '+ widgetTokenBase
        }
    };

    if( method == 'GET' )
    {
        if( Object.keys( data ).length > 0 )
        {
            const newUrl = new URL( url );

            Object.keys( data ).forEach( 
                ( key, index, arrayOriginal ) => newUrl.searchParams.set( key, data[ key ] ) 
            );
            // console.log( url, data, newUrl);
            url = newUrl;
        }
    }
    else if( method == 'POST' )
    {
        if( data instanceof FormData )
        {
            options.body = data;
        }
        else if( Object.keys( data ).length !== 0 )
        {
            options.body = JSON.stringify( data );
            options.headers['Content-Type'] = 'application/json';
        }
    }

    const resultFetch = { 'status' : false, 'result' : '' };

    let response = await fetch( url, options )
    .catch(function(error) {
        console.error('There was a problem with the fetch:' + error.message);
    });

    if( response )
    {
        if( response.ok && response.status == 200 )
        {
            let resultJson = await response.json();
    
            resultFetch.status = true;
            resultFetch.result = resultJson;
        }else{
            resultFetch.status = false;
            console.error('There was a problem with the fetch: ' + response.message);
        }
    }

    return resultFetch;
}

/**
 * Inicializa el widget con los datos obtenidos de la consulta.
 * @param {*} data 
 */
function initializeWidget( data )
{
    const widgetAgents = document.getElementById('busswe-widget');

    // Si existe una nueva sección lo inserta en el widget
    if( data.sections && data.sections.section_2 != '' )
    {
        const mainContentWidget = widgetAgents.querySelector( 'aside details' );
        const desing2ContentWidget = widgetAgents.querySelector( 'aside details #dialog-widget-2' );

        if( mainContentWidget )
        {
            if( desing2ContentWidget )
            {
                initVariablesInDesing2Widget();
            }
            else
            {
                // mainContentWidget.append( data.sections.section_2 );
                mainContentWidget.insertAdjacentHTML( 'beforeend', data.sections.section_2 );
    
                initVariablesInDesing2Widget();
            }
        }
    }


    initBasicElementsInWidget();

    // User Client Token
    if( data.user_client_token != '' )
    {
        if( userClientToken != data.user_client_token )
        setUniqueUserCookie( data.user_client_token );
    }

    // Idioma del widget
    if( data.language != '' )
    widgetAgents.querySelector('#dialog-widget-lang').value = data.language;

    languagePage = widgetAgents.querySelector('#dialog-widget-lang').value;

    // Almacenando la configuración del voice a utilizar.
    activeVoice = data.settings.active_voice;

    // Verificando la posición del widget, si es estatico dentro del cuerpo en lugar de por encima flotando,
    // Se crea un div que contendra al widget.
    if( data.settings.position > 1 && data.settings.position < 4 )
    {
        // Verifica que el parent node que contiene al widget no tenga la clase 
        // para proceder a crear un div con la clase y mover el widget dentro del div.
        if( !widgetAgents.parentNode.classList.contains( 'setToInsideBody' ) )
        {
            // Crea un div como contenedor para el widget agentes.
            const containerPositionWidget = document.createElement( 'div' );
    
            // Al div se le agrega la clase setToInsideBody para anular las proiedades del style position fixed.
            // Y unas dimensiones para el tamaño del contendor.
            containerPositionWidget.classList.add( 'setToInsideBody' );
    
            // Inserta el contenedor al lado del widget agentes.
            widgetAgents.parentNode.insertBefore( containerPositionWidget, widgetAgents.nextSibling );
    
            // Mueve el widget agentes dentro del contenedor.
            containerPositionWidget.append( widgetAgents );            
        }
    }

    // Actualizando la variable que identifica el diseño del widget a utilizar.
    if( data.settings.desing == 2 )
    {
        widgetAgents.classList.add( 'widget-agent-desing-2' );
        checkIfUsingDesign2();
    }

    if( data.settings.show_logo_footer === '0' )
    {
        const footerContainer = widgetAgents.querySelector( ".footer-widget" );

        if( footerContainer && footerContainer.classList.contains( "show-footer-image-company" ) )
            footerContainer.classList.remove( "show-footer-image-company" );
    }


    // Texto del boton flotante
    if( data.texts.initial_button != '' )
    widgetAgents.querySelector('#botton-widget-text').innerHTML = data.texts.initial_button;

    // Texto principal - Title
    if( data.texts.message != '' )
    {
        widgetAgents.querySelector('#dialog-widget-agents-header span').innerHTML = data.texts.message;
        
        widgetAgents.querySelector('#dialog-widget-2 .content-main-items span.title-message').innerText = data.texts.message;
    }
    else
    {
        widgetAgents.querySelector('#dialog-widget-2 .content-main-items span.title-message').innerText = getMessages( 12 );
    }

    // Texto Subtitle
    if( data.texts.subtitle != '' )
    {
        widgetAgents.querySelector('#dialog-widget-2 .content-main-items span.subtitle-message').innerText = data.texts.subtitle;
    }
    else
    {
        widgetAgents.querySelector('#dialog-widget-2 .content-main-items span.subtitle-message').innerText = getMessages( 13 );
    }
    
    
    // Texto mensajes inicial en el chat con los agentes.
    if( data.texts.initial_message != '' )
    messageWidgetAgent = data.texts.initial_message;


    
    const allButtonsMainView = widgetAgents.querySelectorAll('#dialog-widget-2 .content-main-items .group-buttons-actions span');
    if( allButtonsMainView.length )
    {
        let counterButtonsMainView = 13;

        for (let index = 0; index < allButtonsMainView.length; index++) {
            const elementButtonMainView = allButtonsMainView[ index ];
            
            if( elementButtonMainView )
            {
                elementButtonMainView.innerText = getMessages( ++counterButtonsMainView );
            }
        }
    }
    

    // Texto mensajes inicial en el chat con los agentes.
    if( data.texts.button_redirect != '' )
    {
        const btnRedirectUrl = widgetAgents.querySelector('#dialog-widget-2 .content-main-items a.btn-redirect-url-widget-agent span');

        if( btnRedirectUrl )
        btnRedirectUrl.innerText = data.texts.button_redirect;
    }
    else
    {
        const btnRedirectUrl = widgetAgents.querySelector('#dialog-widget-2 .content-main-items a.btn-redirect-url-widget-agent');
        
        if( btnRedirectUrl )
        btnRedirectUrl.style.display = 'none';
    }


    // Botones footer view main
    const buttonFooterMainView = widgetAgents.querySelector('#dialog-widget-2 .container-content-widget .footer-widget ul li.active span');
    if( buttonFooterMainView )
    buttonFooterMainView.innerText = getMessages( 18 );


    // Botones del header en view chat
    const allButtonsHeaderChatView = widgetAgents.querySelectorAll('#dialog-widget-2 .container-main-chat .header-main-chat .group-options-2 span');
    if( allButtonsHeaderChatView.length )
    {
        let counterButtonsHeaderViewChat = 18;
        for (let index = 0; index < allButtonsHeaderChatView.length; index++) {
            const element = allButtonsHeaderChatView[index];
            
            element.innerText = getMessages( ++counterButtonsHeaderViewChat >= 22 ? 24 : counterButtonsHeaderViewChat );
        }
    }


    // Texto al lado del boton que muestra la lista de agentes disponibles
    if( widgetAgents.querySelector('#dialog-widget-agents-button-show-list label') )
    widgetAgents.querySelector('#dialog-widget-agents-button-show-list label').innerText = getMessages( 11 );

    // Placeholder del textarea del chat
    if( widgetAgents.querySelector('#dialog-widget-area-actions-textarea') )
    widgetAgents.querySelector('#dialog-widget-area-actions-textarea').placeholder = getMessages( 10 );

    // Placeholder del textarea del chat - diseño 2
    const textarea2 = widgetAgents.querySelector('#dialog-widget-2 .container-main-chat .body-main-chat .container-options-input textarea');
    if( textarea2 )
    textarea2.placeholder = getMessages( 10 );

    // Grabación de audio - Limite de tiempo de grabación - diseño 2
    const spanTextLimitTimeRecordAudio = widgetAgents.querySelector('#dialog-widget-2 .container-main-chat .body-main-chat .container-options-input .container-options-record-audio .group-option-record-in-process .max-limit-timer-recording-audio');
    if( spanTextLimitTimeRecordAudio )
    spanTextLimitTimeRecordAudio.textContent = getMessages( 22 ) + `: ${maxTimeRecordAudio}s`;

    // Grabación de audio - Titulo de la sección al completar la grabación - diseño 2
    const spanTextTitleSectionRecordAudioFinished = widgetAgents.querySelector('#dialog-widget-2 .container-main-chat .body-main-chat .container-options-input .container-options-record-audio .group-option-record-finished #title-section-recorded-audio-finished');
    if( spanTextTitleSectionRecordAudioFinished )
    spanTextTitleSectionRecordAudioFinished.textContent = getMessages( 23 );

    // Images
    // Logo
    if( data.images.logo != '' )
    widgetAgents.querySelector('#dialog-widget-agents-header img').src = data.images.logo;
    
    // Banner
    if( data.images.banner != '' )
    {
        widgetAgents.querySelector('#dialog-widget-agents-image img').src = data.images.banner;

        const backgroundImageWidget2 = widgetAgents.querySelector('#dialog-widget-2 .container-content-widget .main-content-widget .background-media');
        if( backgroundImageWidget2 )
        {
            backgroundImageWidget2.style.backgroundImage = `url('${ data.images.banner }')`;
        }

    }
    else{
        widgetAgents.querySelector("#dialog-widget").classList.add('widgetWithoutBanner');
    }

    // Url Banner
    if( data.urls.banner != '' ){
        widgetAgents.querySelector('#dialog-widget-agents-image img').addEventListener('click', () => location.href = data.urls.banner );
        widgetAgents.querySelector('#dialog-widget-agents-image img').style.cursor = 'pointer';


        const btnRedirectUrl = widgetAgents.querySelector('#dialog-widget-2 .content-main-items a.btn-redirect-url-widget-agent');
        if( btnRedirectUrl )
        btnRedirectUrl.href = data.urls.banner;
    }

    // Url video
    if( data.videos.video_background != '' )
    {
        // data.videos.video_background_view
        // const videoElement = widgetAgents.querySelector( '#dialog-widget-2 .container-content-widget .main-content-widget .background-media video' );
        if( videoBackgroundWidget2 )
        {
            videoBackgroundWidget2.src = data.videos.video_background;
            
            // Asigna clase para hacer zoom y centrar el video.
            if( data.videos.video_background_view && data.videos.video_background_view == '2' )
            videoBackgroundWidget2.classList.add( 'cover-center' );

            // Hailitar el audio del video de fondo.
            if( data.videos.active_audio_video_background == true )
            activeAudioVideoBackgroundWidget2 = true;
        }        
    }

    // Colores
    // Color de fondo
    if( data.colors.background != '' )
    widgetAgents.querySelector('#dialog-widget').style.backgroundColor = data.colors.background;

    // Color de las letras
    if( data.colors.letter != '' )
    widgetAgents.querySelector('#dialog-widget-agents-header').style.color = data.colors.letter;

    // Boton Color de fondo
    if( data.colors.button_color_background != '' )
    {
        const items = widgetAgents.querySelectorAll('.btnWidgetAgent');
        // colorBackgroundButton = data.colors.button_color_background;

        for (let index = 0; index < items.length; index++) {
            const element = items[index];
            element.style.backgroundColor = data.colors.button_color_background;
        }

        document.documentElement.style.setProperty( "--colorBackgroundButtonWidgetAgents", data.colors.button_color_background );

        document.documentElement.style.setProperty('--widget-agents-2-option-button-1-color', data.colors.button_1_color_background);
        document.documentElement.style.setProperty('--widget-agents-2-option-button-2-color', data.colors.button_2_color_background);
        document.documentElement.style.setProperty('--widget-agents-2-option-button-3-color', data.colors.button_3_color_background);
    }

    // Boton Color de las letras
    if( data.colors.button_color_text != '' ){
        Array.from( widgetAgents.querySelectorAll('.btnWidgetAgent') ).map( function ( item ) {
            
            return item.style.color = data.colors.button_color_text;
        });

        document.documentElement.style.setProperty('--widget-agents-2-option-button-1-text', data.colors.button_1_color_text);
        document.documentElement.style.setProperty('--widget-agents-2-option-button-2-text', data.colors.button_2_color_text);
        document.documentElement.style.setProperty('--widget-agents-2-option-button-3-text', data.colors.button_3_color_text);
    }


    // Color de fondo principal
    if( data.colors.letter != '' )
    {
        widgetAgents.querySelector('#dialog-widget-agents-image').style.backgroundColor = data.colors.background_main;
        
        widgetAgents.querySelector('#dialog-widget-agents-button-show-list').style.backgroundColor = data.colors.background_main;

        widgetAgents.querySelector('#dialog-widget-agents-button-show-list button').style.backgroundColor = data.colors.background_main;

        widgetAgents.querySelector('#dialog-widget-agents-list').style.backgroundColor = data.colors.background_main;

        document.documentElement.style.setProperty('--widget-agents-2-background-color-1', data.colors.background_main);
        document.documentElement.style.setProperty('--widget-agents-2-background-color-2', data.colors.background_main_2);
        document.documentElement.style.setProperty('--widget-agents-2-footer-button-background', data.colors.background_main_footer);
    }

    // Color de las letras principal
    if( data.colors.letter_main != '' )
    {
        widgetAgents.querySelector('#dialog-widget').style.color = data.colors.letter_main;
        
        // Boton que muestra la lista de los agentes
        widgetAgents.querySelector('#dialog-widget-agents-button-show-list svg').style.color = data.colors.letter_main;

        document.documentElement.style.setProperty('--widget-agents-2-text-title', data.colors.letter_main);
        document.documentElement.style.setProperty('--widget-agents-2-text-subtitle', data.colors.letter_main_2);
        document.documentElement.style.setProperty('--widget-agents-2-text-shadow-title', data.colors.letter_main_shadow);

        document.documentElement.style.setProperty('--widget-agents-2-footer-button-color', data.colors.letter_main_footer);
        document.documentElement.style.setProperty('--widget-agents-2-footer-button-color-active', data.colors.letter_main_footer_2);
    }



    // Agentes
    insertAgentToList( widgetAgents, data.agents );



    // Color de las letras y el icono sobre el estado en linea del agente.
    if( data.colors.letter_online_status != '' )
    {
        // console.log( "color para los estados "+data.colors.letter_online_status );
        const spansAgentsOnlineStatus = widgetAgents.querySelectorAll('#dialog-widget-agents-list article section span.item-agent-status');

        if( spansAgentsOnlineStatus.length > 0 )
        
        for (let index = 0; index < spansAgentsOnlineStatus.length; index++) {
            const element = spansAgentsOnlineStatus[index];
            
            element.style.color = data.colors.letter_online_status;
            
            if( element.querySelector('i') )
            element.querySelector('i').style.backgroundColor = data.colors.letter_online_status;
        }
    }


    // Comprueba si en el DOM esta presente el button del Widget Schedule Appointment
    if( buttonToWidgetScheduleAppointment )
    {
        // Se muestran los botones del widget y se agrega el evento para dar click al boton del widget schedule appointment.
        const allButtonsToWidgetExternal = widgetAgents.querySelectorAll( '#dialog-widget-2 .option-to-show-widget-schedule-appointment' );

        if( allButtonsToWidgetExternal && allButtonsToWidgetExternal.length > 0 )
        {
            for (let index = 0; index < allButtonsToWidgetExternal.length; index++) {
                const elementButtonWidgetExternal = allButtonsToWidgetExternal[index];
                
                elementButtonWidgetExternal.addEventListener( 'click', () => {
                    buttonToWidgetScheduleAppointment.click();
                } );

                elementButtonWidgetExternal.classList.add( 'show-option-widget-external' );
            }
        }
    }

    // Comprueba si en el DOM esta presente el button del Widget Call Agent
    if( buttonToWidgetCallAgent )
    {
        // Se muestran los botones del widget y se agrega el evento para dar click al boton del widget call agent.
        const allButtonsToWidgetExternal = widgetAgents.querySelectorAll( '#dialog-widget-2 .option-to-show-widget-call-agent' );

        if( allButtonsToWidgetExternal && allButtonsToWidgetExternal.length > 0 )
        {
            for (let index = 0; index < allButtonsToWidgetExternal.length; index++) {
                const elementButtonWidgetExternal = allButtonsToWidgetExternal[index];
                
                elementButtonWidgetExternal.addEventListener( 'click', () => {
                    buttonToWidgetCallAgent.click();
                } );

                elementButtonWidgetExternal.classList.add( 'show-option-widget-external' );
            }
        }
    }
    

    // Settings - Show Widget when DomLoad end
    if( data.settings.inital_show != 0 )
    {
        const showWidgetAgents = () => {
            widgetAgents.querySelector('details').open = true;
        };

        if( data.settings.timer_inital_show == 0 )
            showWidgetAgents();

        else
        setTimeout( () => { showWidgetAgents() }, data.settings.timer_inital_show * 1000 );
    }

    // Settings - Position
    if( data.settings.position == 1 || data.settings.position == 3 ){
        widgetAgents.classList.add('setToRight');
    }


    // Mostrando el widget
    widgetAgents.classList.remove('widget-agent-init');

    initEventToShowChatWithAgent();

    ttsNavigator.initTTSNavigator();

    if( videoBackgroundWidget2 )
    setSecuenceToPlayVideoBackgroundWidget2();

    // Verifica para seleccionar un agente si es el unico.
    // Solo funciona en el segundo diseño del widget.
    if( ( data.settings.show_one_agent !== false || data.settings.initial_show_chat !== '' ) && boolUseDesign2 )
    {
        // Comprueba la cantidad de agentes disponibles y busca el que coincida en el id del setting.
        if( data.agents.length > 0 )
        {
            // Constante con el id de agente a buscar entre el array de agents,
            // por la condicional primero se comprueba el show_one_agent por prioridad (unico agente o el agente de una campaña de marketing),
            // si la primera opción no pasa la condicional entonces se asigna la segunda init_show_chat (id de un agente para mostrar su chat).
            const agentIdToSearch = data.settings.show_one_agent !== false ? data.settings.show_one_agent : data.settings.initial_show_chat;

            // Variable para identificar si se encontro el agent por su id en el array de agents.
            let agentFinded = false;

            for (let index = 0; index < data.agents.length; index++) {
                const elementAgent = data.agents[index];
                
                if( agentFinded === false && elementAgent.id == agentIdToSearch )
                agentFinded = elementAgent.id;
            }

            // Encontrado el agente que conincide con el id.
            if( agentFinded !== false )
            {
                // Encuentra el objeto del agente en el html.
                const itemAgent = widgetAgents.querySelector( `#item-2-agent-${ agentFinded }` );
                
                // Encuentra el objeto button Chat en el html.
                const itemButtonChat = widgetAgents.querySelector( `.footer-widget .show-chat-button.show-option-to-contact-agent-button` );
                

                // Ejecuta la función para seleccionar el agente del id, para seleccionar el agente y se muestren las opciones,
                // como el boton ir al chat.
                if( itemAgent )
                {
                    // itemAgent.click();
                    selectAgentInListInWidget( itemAgent.dataset.agentId, itemAgent.id );

                    // Verifica si el URL tiene un paramentro especifico para enviar por el chat un mensaje predeterminado.
                    const sendClientMessageIfWSA = () => {
                        const urlQueryString = new URL( window.location.href );
                        const urlParams = urlQueryString.searchParams;

                        if( urlParams.has( 'cid_wsa' ) && containerMessages2 )
                        {
                            const totalMsgInChat = containerMessages2.childNodes.length;

                            const lastMessage = totalMsgInChat ? containerMessages2.childNodes[ totalMsgInChat - 1 ] : null;

                            if( lastMessage && lastMessage.classList.contains('message-agent') )
                            {
                                const tempMessageExt = languagePage == 'en' ? "I've already finished scheduling" : 'Ya termine de agendar';
                                
                                insertMessageInHistoryWidget( 2, tempMessageExt );
                                sendMessageClientToAgentInSystem( tempMessageExt );
                            }

                            // Elimina el parametro de la url por si el usuario recarga la pagina.
                            urlParams.delete( 'cid_wsa' );

                            window.history.pushState({}, '', urlQueryString);
                        }
                    }

                    if( data.settings.initial_show_chat !== '' )
                    {
                        if( activeVoice == 0 )
                        {
                            itemButtonChat.click();

                            sendClientMessageIfWSA();
                        }
                        else
                        {
                            const modalAdviseIsActive = initModalAdviseForTapTheScreen();
    
    
                            if( modalAdviseIsActive )
                            {
                                let showModal = true;
    
                                const showChatAgent = () => {
                                    if( !showModal ) return;
    
                                    showModal = false;
    
                                    removeContainerAdviseForTapTheScreenInWidgetAgent( false );
                                    
                                    itemButtonChat.click();

                                    sendClientMessageIfWSA();
    
                                    document.body.removeEventListener( "click", () => showChatAgent() );
                                };
    
                                document.body.addEventListener( "click", () => showChatAgent() );
                            }
                            else
                            {
                                if( data.settings.timer_inital_show == 0 )
                                {
                                    itemButtonChat.click();
                                    sendClientMessageIfWSA();
                                }
                                else
                                setTimeout( () => { itemButtonChat.click() }, data.settings.timer_inital_show * 1000 );
                            }
                        }
                    }
                }
            }
        }
    }
}

/**
 * Lista e inserta los agentes en la lista del widget.
 * @param {*} widget 
 * @param {*} agents 
 */
function insertAgentToList( widget, agents )
{
    const placeAgentList = boolUseDesign2 ?
    widget.querySelector('.list-group-agents-icons') : widget.querySelector('#dialog-widget-agents-list');

    if( !placeAgentList ) return;

    agentsList = agents;

    // Limpiando la lista agentes
    placeAgentList.innerHTML = '';
    const totalAgents = agents.length;

    if( totalAgents > 0 )
    {
        const iconSvgHand = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-index" viewBox="0 0 16 16"><path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 1 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 0 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.035a.5.5 0 0 1-.416-.223l-1.433-2.15a1.5 1.5 0 0 1-.243-.666l-.345-3.105a.5.5 0 0 1 .399-.546L5 8.11V9a.5.5 0 0 0 1 0V1.75A.75.75 0 0 1 6.75 1M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v5.34l-1.2.24a1.5 1.5 0 0 0-1.196 1.636l.345 3.106a2.5 2.5 0 0 0 .405 1.11l1.433 2.15A1.5 1.5 0 0 0 6.035 16h6.385a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5 5 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.6 2.6 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046zm2.094 2.025"/></svg>`;

        for (let index = 0; index < totalAgents; index++) {
            const element = agents[index];
            
            if( boolUseDesign2 )
            {
                const itemLi = document.createElement('li');
                const itemImg = document.createElement('img');

                itemImg.src = element.icon_id;
                itemImg.alt = `image-icon-agent-${ index }-widget-agent`;
                itemLi.id = `item-2-agent-${ element.id }`;
                itemLi.dataset.agentId = element.id;

                itemLi.addEventListener( 'click', ( e ) => {
                    const elementAgent = e.currentTarget;

                    selectAgentInListInWidget( elementAgent.dataset.agentId, elementAgent.id );
                } );

                itemLi.appendChild( itemImg );
                placeAgentList.appendChild( itemLi );
            }
            else
            {
                const agent = createItemAgent( element );
    
                if( index == 0 )
                {
                    agent.classList.add( 'hightlight-agent' );
    
                    const containerIconHand = document.createElement( 'div' );
                    containerIconHand.innerHTML = iconSvgHand;
    
                    agent.appendChild( containerIconHand );
                }
    
                placeAgentList.appendChild( agent );
            }
        }

    }
    else 
    {
        const message = document.createElement('div');
        message.style.textAlign = 'center';
        message.innerText = languagePage == 'es' ? 'No hay agentes disponibles' : 'No agents available';
    }
}

/**
 * Funciones a ejecutar al seleccionar una agente de la lista disponible en el widget.
 */
function selectAgentInListInWidget( agentId, elementAgentId )
{
    selectAgent( agentId, false, false, false );
    hightlightAgentSelected( elementAgentId );
}

/**
 * Crea las etiquetas html para el item agente.
 * @param {*} agent 
 * @returns 
 */
function createItemAgent ( agent )
{
    // Imagen del agente
    const img = document.createElement('img');
    img.src = agent.icon_id;
    img.alt = 'item-agent-list';

    // Campo que contiene la imagen
    const picture = document.createElement('picture');
    picture.appendChild( img );

    // Profesión del agente
    const span1 = document.createElement('span');
    span1.classList.add('item-agent-profession');
    span1.innerHTML = agent.profession;

    // Nombre del agente
    const bToSpan2 = document.createElement('b');
    bToSpan2.innerHTML = agent.name;

    const span2 = document.createElement('span');
    span2.classList.add('item-agent-name');
    span2.appendChild( bToSpan2 );

    // Estado del agente
    const iconStatus = document.createElement('i');
    const textToSpan3 = document.createTextNode( getMessages( 8 ) );

    const span3 = document.createElement('span');
    span3.classList.add('item-agent-status');
    span3.appendChild(iconStatus);
    span3.appendChild(textToSpan3);

    // Creando la base del item
    const article = document.createElement('article');
    const sectionContent = document.createElement('section');
    
    // Insertando los elementos creados
    sectionContent.appendChild( span1 );
    sectionContent.appendChild( span2 );
    sectionContent.appendChild( span3 );

    article.appendChild( picture );
    article.appendChild( sectionContent );

    // Evento click al seleccionar el agente
    // article.setAttribute('onclick', `selectAgent( ${agent.id} )`);

    article.addEventListener('click', () => {
        selectAgent( agent.id );

        removeHighLightAgents();
    });

    return article;
}

/**
 * Inserta nuevos mensajes de texto en el history.
 * @param {*} typeUser 
 * @param {*} message 
 * @param {*} isAElement 
 * @param {*} isWritting 
 */
function insertMessageInHistoryWidget( typeUser, messageOriginal, isAElement = false, isWritting = false, voiceUrl = '', useHyperLinks = false )
{
    // TypeUser: 1 => System, 2 => User
    if( typeUser && ( typeUser == 1 || typeUser == 2 ) && messageOriginal != '')
    {
        const historyMessagesWidget = boolUseDesign2 ?
        containerMessages2 : document.getElementById('dialog-widget-area-history-content');

        const item = document.createElement('div');
        const span = document.createElement('span');

        let itemMessageWithButtons = null;
        let itemImagesInMessage = null;

        span.style.wordBreak = 'break-word';

        const itemClass = ['widget-area-history-item'];

        let typeItemClass = '';

        switch( typeUser )
        {
            case 1:
                typeItemClass = boolUseDesign2 ? 'message-agent' : 'item-system';
                break;

            case 2:
                typeItemClass = boolUseDesign2 ? 'message-client' : 'item-client';
                break;
        }

        if( typeUser == 1 && isWritting )
        {
            // Si es usuario del sistema y is writting es true, se insertara un mensaje con un icono de espera
            const icon1 = document.createElement('i'); icon1.classList.add('wait-1');
            const icon2 = document.createElement('i'); icon2.classList.add('wait-2');
            const icon3 = document.createElement('i'); icon3.classList.add('wait-3');

            span.appendChild(icon1);
            span.appendChild(icon2);
            span.appendChild(icon3);

            span.classList.add('message-wait-writting-widget');
        }
        else
        {
            if( isAElement )
            span.appendChild( messageOriginal );
    
            else
            {
                const message = filterElementsFromMessages.processString( 
                    messageOriginal, languagePage,
                    typeUser == 2 ? 1 : ( useHyperLinks ? 2 : 1 )
                );

                span.innerHTML = message;

                if( typeUser == 1 && boolUseDesign2 && useHyperLinks )
                {
                    // console.log("creando botones");
                    itemMessageWithButtons = createContainerButtonsForMessageChat( 
                        messageOriginal, typeItemClass, historyMessagesWidget
                    );

                    itemImagesInMessage = createImageObjectsForMessageChat( 
                        messageOriginal, typeItemClass, historyMessagesWidget
                    );
                }
            }
        }

        // Verifica si el segundo diseño esta activo y el mensaje es de un agente, 
        // para crear una etiqueta img para insertar la imagen del agente.
        if( boolUseDesign2 && typeUser == 1 && agent_selected )
        {
            const containerPicture = document.createElement('picture');
            const iconAgent = document.createElement('img');

            iconAgent.src = agent_selected.icon_id;
            containerPicture.appendChild( iconAgent );

            item.appendChild( containerPicture );
        }


        item.appendChild( span );

        if( !boolUseDesign2 )
        item.classList.add(itemClass);

        item.classList.add(typeItemClass);

        historyMessagesWidget.appendChild( item );
        
        if( itemMessageWithButtons )
        historyMessagesWidget.appendChild( itemMessageWithButtons );

        if( itemImagesInMessage && itemImagesInMessage.length )
        itemImagesInMessage.map( item => historyMessagesWidget.appendChild( item ) );

        scrollChatToBotton();

        if( activeVoice == 1 && agent_selected && typeUser == 1 && !isWritting )
        {
            if( voiceUrl == '' && agent_selected.tts_voice_id == null && !filterElementsFromMessages.isHTML( messageOriginal ) && !isAElement )
            {
                ttsNavigator.addTextAndSpeak( messageOriginal );
            }
            else if( voiceUrl != '' && agent_selected.tts_voice_id != null )
            {
                ttsVoiceChatbot.addTrackToQueue( voiceUrl );
            }
        }
    }
}

/**
 * 
 * @param {*} arrayTargets 
 * @returns 
 */
function createButtonsInMessageChatFromArray( arrayTargets )
{
    const createButtonAction = ( text, svg, href, newTab, classes ) => {
        const buttonMsg = document.createElement('a');
        const textButton = document.createElement('span');
        
        textButton.textContent = text;
        buttonMsg.href = href;
        buttonMsg.appendChild( textButton );

        textButton.innerHTML += svg;

        if( newTab )
            buttonMsg.target = "_blank";
        
        if( classes != '' )
            buttonMsg.classList.value = ' ' + classes;

        return buttonMsg;
    }

    const createButtonByType = ( typeButton, targetHref ) => {
        const arrayOptions = {
            phones_call: {
                text: languagePage == 'en' ? 'Call' : 'Llamar',
                svg: '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path d="M6.62 10.79c1.44 2.83 3.76 5.15 6.59 6.59l2.2-2.2c.28-.28.67-.36 1.02-.25c1.12.37 2.32.57 3.57.57a1 1 0 0 1 1 1V20a1 1 0 0 1-1 1A17 17 0 0 1 3 4a1 1 0 0 1 1-1h3.5a1 1 0 0 1 1 1c0 1.25.2 2.45.57 3.57c.11.35.03.74-.25 1.02z"/></svg>',
                target: 'tel:',
                newTab: false,
                class: 'phone-button-message-hyperlink'
            },
            phones_msg: {
                text: languagePage == 'en' ? 'Message' : 'Mensaje',
                svg: '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144l-2.494.654l.666-2.433l-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931a6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646c-.182-.065-.315-.099-.445.099c-.133.197-.513.646-.627.775c-.114.133-.232.148-.43.05c-.197-.1-.836-.308-1.592-.985c-.59-.525-.985-1.175-1.103-1.372c-.114-.198-.011-.304.088-.403c.087-.088.197-.232.296-.346c.1-.114.133-.198.198-.33c.065-.134.034-.248-.015-.347c-.05-.099-.445-1.076-.612-1.47c-.16-.389-.323-.335-.445-.34c-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992c.47.205.84.326 1.129.418c.475.152.904.129 1.246.08c.38-.058 1.171-.48 1.338-.943c.164-.464.164-.86.114-.943c-.049-.084-.182-.133-.38-.232"/></svg>',
                target: 'https://wa.me/',
                newTab: true,
                class: 'phone-button-message-hyperlink'
            },
            emails: {
                text: languagePage == 'en' ? 'Send Email' : 'Enviar Email',
                svg: '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path d="M18 7.373V14.5a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 2 14.5V7.373l7.746 4.558a.5.5 0 0 0 .508 0zM15.5 4a2.5 2.5 0 0 1 2.485 2.223L10 10.92L2.015 6.223A2.5 2.5 0 0 1 4.5 4z"/></svg>',
                target: 'mailto:',
                newTab: false,
                class: ''
            },
            websites: {
                text: languagePage == 'en' ? 'Go to Link' : 'Ir al Enlace',
                svg: '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path d="M17 17H3V3h5V1H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5h-2z"/><path d="m11 1l3.29 3.29l-5.73 5.73l1.42 1.42l5.73-5.73L19 9V1z"/></svg>',
                target: '',
                newTab: true,
                class: ''
            }
        };

        return createButtonAction(
            arrayOptions[ typeButton ].text,
            arrayOptions[ typeButton ].svg,
            arrayOptions[ typeButton ].target + targetHref,
            arrayOptions[ typeButton ].newTab,
            arrayOptions[ typeButton ].class
        );
    }

    if( 
        arrayTargets.phones.length || arrayTargets.phones_call.length || arrayTargets.phones_msg.length ||
        arrayTargets.emails.length || arrayTargets.websites.length 
    )
    {
        const nameFieldsButtons = [ 'phones', 'phones_call', 'phones_msg', 'emails', 'websites' ];
        const nameFiledPhones = [ 'phones', 'phones_call', 'phones_msg' ];
        const arrayButtonsFinal = [];

        // Recorre el array de nombre de los tipos de buttons, para utilizarlos en el arrayTargets.
        // Asi acceder a los campos de forma dinamica.
        for (let index = 0; index < nameFieldsButtons.length; index++) {
            const elementNameTypeButton = nameFieldsButtons[index];
            
            // El elemento es un phone call o msg y tienen por lo menos uno.
            const isPhoneCallOrMsgAndCount = ( nameFiledPhones.includes( elementNameTypeButton ) && elementNameTypeButton != 'phones' )
            && ( arrayTargets[ nameFiledPhones[ 1 ] ].length && arrayTargets[ nameFiledPhones[ 2 ] ].length );

            // Total de elementos en el tipo.
            const totalItemsInTypeButton = isPhoneCallOrMsgAndCount ? 2 : arrayTargets[ elementNameTypeButton ].length;
            
            // Recorre todos los elementos del tipo en el array.
            arrayTargets[ elementNameTypeButton ].map( itemTargetButton => {

                const divContainerInfoButtons = totalItemsInTypeButton > 1 ? document.createElement("div") : null;
                let valueButton = null;

                for (let index2 = 0; index2 < 2; index2++) {
                    
                    const postNameTypeButton = nameFiledPhones.includes( elementNameTypeButton ) ? 
                    ( 
                        ( index2 == 0 && elementNameTypeButton == 'phones' ) || elementNameTypeButton == 'phones_call' ? 
                        '_call' : '_msg' 
                    ) 
                    : '';

                    if(
                        ( nameFiledPhones.includes( elementNameTypeButton ) && 
                            (
                                elementNameTypeButton == 'phones' || ( elementNameTypeButton != 'phones' && index2 == 0 )
                            ) 
                        )
                        || 
                        ( !nameFiledPhones.includes( elementNameTypeButton ) && index2 == 0 ) 
                    )
                    {
                        // Crea constante con el valor de un regex si el button a crear es un telefono,
                        // si no lo es entonces se asigna un string vacio.
                        const regexCleanPhone = nameFiledPhones.includes( elementNameTypeButton ) ? 
                        ( postNameTypeButton == '_call' ? /[^\d+]/g : /[^\d]/g ) : '';

                        // Crea constante para asignar el valor target del button, si la constante anterior no es un string vacio
                        // entonces reemplaza los caracteres del string target utilizando el regex antes de asignarlo.

                        // Si el regex es un string vacio, entonces asigna de forma directa el valor target para el button.
                        const targetButtonValue = regexCleanPhone != '' ? 
                        itemTargetButton.replace( regexCleanPhone, "" ) : itemTargetButton;

                        const nameTypeButton = elementNameTypeButton + 
                        ( 
                            nameFiledPhones.includes( elementNameTypeButton ) && elementNameTypeButton != 'phones'
                            ? '' : postNameTypeButton 
                        );


                        const itemButton = createButtonByType(
                            nameTypeButton,
                            targetButtonValue
                        );


                        if( divContainerInfoButtons == null )
                            arrayButtonsFinal.push( itemButton );
                        else
                        {
                            valueButton = itemTargetButton;

                            divContainerInfoButtons.appendChild( itemButton );
                        }
                    }
                }
                
                if( divContainerInfoButtons != null )
                {
                    divContainerInfoButtons.classList.add( 'show-info-target-button-container' );

                    const spanInfoButton = document.createElement('span');
                    spanInfoButton.textContent = valueButton;

                    divContainerInfoButtons.prepend( spanInfoButton );

                    arrayButtonsFinal.push( divContainerInfoButtons );
                }

            } );
        }

        return arrayButtonsFinal;
    }
}


function createContainerButtonsForMessageChat( messageOriginal, typeClassMessage, historyMessagesWidget )
{
    const elementsForButtons = filterElementsFromMessages.getContactElementsFromString( messageOriginal );
    // console.log("Elementos de contacto del mensaje", elementsForButtons);

    const elementFromContainersForButtons = filterElementsFromMessages.getContactElementsTemplatesFromString( messageOriginal );

    // Comprueba si alguno de los campos phones del container tiene contenido.
    const somePhonesContainersHasValue = elementFromContainersForButtons.phones_both.length || 
        elementFromContainersForButtons.phones_call.length ||
        elementFromContainersForButtons.phones_sms.length;
        
    // Si hay por lo menos un registro en alguno de los phone container, entonces reemplaza todos los valores phones e inicializa algunos
    // antes de crear los elements buttons.
    if( somePhonesContainersHasValue )
    elementsForButtons.phones = elementFromContainersForButtons.phones_both;

    elementsForButtons.phones_call = somePhonesContainersHasValue ? elementFromContainersForButtons.phones_call : [];
    elementsForButtons.phones_msg = somePhonesContainersHasValue ? elementFromContainersForButtons.phones_sms : [];

    // Filtrando los Urls Website para que no tenga Url de imagenes que tenga el segundo grupo de containers.
    if( elementsForButtons.websites.length && elementFromContainersForButtons.images.length )
    {
        elementsForButtons.websites = elementsForButtons.websites.filter( itemWebsite => {
            
            if( !elementFromContainersForButtons.images.includes( itemWebsite ) )
            return itemWebsite;

        } );
    }

    // Crea los botones mensajes
    const buttonsFromMessage = createButtonsInMessageChatFromArray( elementsForButtons );

    if( !buttonsFromMessage || ( buttonsFromMessage && buttonsFromMessage.length < 1 ) )
    return null;

    const totalItemInHistoryMessages = historyMessagesWidget.childElementCount;

    const containerGroupButtonsId = `chat-messages-group-buttons-${ totalItemInHistoryMessages }`;
    const classNameContainerGroupButtons = 'container-all-group-buttons';

    const buttonShowGroupButtons = document.createElement( 'button' );
    const containerAllGroupButtons = document.createElement( 'div' );
    const itemMessageWithButtons = document.createElement( 'div' );

    itemMessageWithButtons.id = containerGroupButtonsId;

    itemMessageWithButtons.classList.add( typeClassMessage, 'hyperlinks-buttons-message' );
    containerAllGroupButtons.classList.add( classNameContainerGroupButtons );
    buttonShowGroupButtons.classList.add( 'btn-toggle-show-group-buttons' );

    if( buttonsFromMessage.length < 4 )
    containerAllGroupButtons.classList.add( 'show-group-buttons' );

    buttonShowGroupButtons.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path d="M231.39 123.06A8 8 0 0 1 224 128h-40v80a16 16 0 0 1-16 16H88a16 16 0 0 1-16-16v-80H32a8 8 0 0 1-5.66-13.66l96-96a8 8 0 0 1 11.32 0l96 96a8 8 0 0 1 1.73 8.72"/></svg>';
    
    buttonsFromMessage.map( itemButton => {
        containerAllGroupButtons.appendChild( itemButton );
    } );

    if( buttonsFromMessage.length > 3 )
    buttonShowGroupButtons.addEventListener( 'click', () => {

        // Obtiene el container del grupo de botones.
        const containerGroupButtons = historyMessagesWidget.querySelector( `#${containerGroupButtonsId} .${classNameContainerGroupButtons}` );

        // console.log("un evento, container es", containerGroupButtons);

        if( !containerGroupButtons ) return;

        containerGroupButtons.classList.toggle( 'show-group-buttons' );

    } );

    itemMessageWithButtons.appendChild( containerAllGroupButtons );
    
    if( buttonsFromMessage.length > 3 )
    itemMessageWithButtons.appendChild( buttonShowGroupButtons );

    return itemMessageWithButtons;
}

/**
 * 
 * @param {*} messageOriginal 
 * @param {*} typeClassMessage 
 * @param {*} historyMessagesWidget 
 * @returns 
 */
function createImageObjectsForMessageChat( messageOriginal, typeClassMessage )
{
    const arrayImageUrls = filterElementsFromMessages.getArrayElementsUrlImages( messageOriginal );

    // Comprueba si hay url imagenes en el array.
    if( arrayImageUrls.length < 1 ) return;

    const urlBase = widgetUrlBase.replace( 'api/', '' );

    const createContainerImage = ( url ) => {

        const container = document.createElement( "div" );
        container.classList.add( typeClassMessage );

        const spanItem = document.createElement("span");
        
        const itemImage = document.createElement( "img" );
        itemImage.classList.add( "object-image-message" );
        itemImage.loading = "lazy";
        itemImage.src = url;
        itemImage.onerror = () => itemImage.src = urlBase + urlImagePlaceHolder;

        itemImage.addEventListener( 'click', () => {
            toggleShowModalImgWC( true, url );
        });

        spanItem.append( itemImage );
        container.append( spanItem );

        // container.append( itemImage );

        return container;
    }

    const arrayContainerImages = [];

    for (let index = 0; index < arrayImageUrls.length; index++) {
        const elementUrl = arrayImageUrls[index];
        
        arrayContainerImages.push( createContainerImage( elementUrl ) );
    }


    return arrayContainerImages;
}

/**
 * Inserta los medios de contacto del agente.
 */
function insertAgentContact()
{
    // Inicializan variables con los valores basicos de contactos
    const namesContact = [ 'messenger', 'whatsapp', 'telegram' ];
    // Estableciendo los numeros disponibles del agente
    const agentNumbers = {'messenger' : agent_selected.messenger, 'telegram' : agent_selected.telegram, 'whatsapp' : agent_selected.phone};
    // Los iconos para identificar los medios de contacto
    const arraySVG = {
        'messenger' : '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="margin-right:.5rem;" class="bi bi-messenger" viewBox="0 0 16 16"><path d="M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.639.639 0 0 0-.427.03l-1.588.702a.64.64 0 0 1-.898-.566l-.044-1.423a.639.639 0 0 0-.215-.456C.956 12.108 0 10.092 0 7.76zm5.546-1.459-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 0 1 .578-.002l1.869 1.402a1.2 1.2 0 0 0 1.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 0 1-.578.002L7.281 5.98a1.2 1.2 0 0 0-1.735.32z"/></svg>',
        'whatsapp' : '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="margin-right:.5rem;" class="bi bi-whatsapp" viewBox="0 0 16 16"><path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/></svg>',
        'telegram' : '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="margin-right:.5rem;" class="bi bi-telegram" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/></svg>'
    };
    // Las direcciones para redireccionar
    const arrayHref = {
        'messenger' : 'https://m.me/',
        'whatsapp' : 'https://wa.me/',
        'telegram' : 'https://telegram.me/'
    };


    for (let index = 0; index < namesContact.length; index++) {
        const element = namesContact[index];
        
        if( agentNumbers[ element ] != '' && agentNumbers[ element ] != null ){
            const item = document.createElement('a');
            item.style.textDecoration = 'none';
            item.style.color = 'inherit';
            item.style.textTransform  = 'capitalize';
            item.style.display = 'flex';
            item.style.flexDirection = 'row';
            item.style.alignContent = 'center';
            item.style.alignItems = 'center';
            item.style.justifyContent = 'left';
            item.target = '_blank';

            item.innerHTML = arraySVG[ element ].trim() + ' ' + element;
            item.href = arrayHref[ element ] + agentNumbers[ element ];

            if( element == 'whatsapp' ){
                let text = getMessages( 9, { 'agent' : agent_selected.name, 'client' : client_contact.name } );

                item.href += '?text='+encodeURIComponent( text );
            }

            insertMessageInHistoryWidget( 1, item , true);
        }

    }
}

/**
 * Remueve la clase highlight del agent para que no muestre más efectos para destacar entre las opciones del widget.
 * @returns 
 */
function removeHighLightAgents()
{
    const allAgentsHighLight = document
    .querySelectorAll('#busswe-widget #dialog-widget-agents-list article.hightlight-agent');
    if( allAgentsHighLight.length < 1 ) return;

    for (let index = 0; index < allAgentsHighLight.length; index++) {
        const element = allAgentsHighLight[index];
        
        // if( article.classList.contains( 'hightlight-agent' ) )
        element.classList.remove( 'hightlight-agent' );
    }
}

/**
 * Limpiar el history de todos los mensajes presentes
 */
function cleanUpHistoryWidget()
{
    const historyMessagesWidget = boolUseDesign2 ? 
    containerMessages2 : document.getElementById('dialog-widget-area-history-content');

    if( !historyMessagesWidget ) return;

    while ( historyMessagesWidget.firstChild ) {
        historyMessagesWidget.removeChild( historyMessagesWidget.firstChild );
    }   
}

/**
 * Procesa, valida y envia el mensaje del usuario usando el input para despues insertarlo en el history.
 * @returns 
 */
function submitMessageToHistory()
{
    const textarea = boolUseDesign2 ? document.querySelector('#busswe-widget #dialog-widget-2 .container-options-input textarea') : document.getElementById('dialog-widget-area-actions-textarea');
    const buttonSubmit = boolUseDesign2 ? document.querySelector('#busswe-widget #dialog-widget-2 .container-options-input button.button-submit-message-chat') : document.getElementById('dialog-widget-area-actions-submit');
    
    if( textarea && buttonSubmit )
    {
        // agent_selected.use_system

        toogleDisableWidgetAreaActions( true );

        const message = textarea.value;
        
        if( message == '' && statusRegisterHistory ){
            toogleDisableWidgetAreaActions( false );
            return;
        } 

        // Envia el mensaje al history
        insertMessageInHistoryWidget( 2, message );

        saveMessageChatInAgentSelected( 2, message );
        
        // Validar los mensajes relevantes para el formulario
        if( statusRegisterHistory ){
            inputUser = removeExtraWhiteSpaceInString( message, true );

            if( agent_selected && !agent_selected.use_system )
            nextInProgressHistoryMessage();
        }

        if( agent_selected.use_system )
        {
            sendMessageClientToAgentInSystem( message );
        }

        textarea.value = '';

        // toogleDisableWidgetAreaActions( false );
    }
}

/**
 * Altena el estado disable de los elementos textarea y buttonSubmit, 
 * son los elementos usados por el usaurios para enviar su mensaje al Chat.
 * @param {*} status 
 * @returns 
 */
function toogleDisableWidgetAreaActions( status )
{
    const textarea = boolUseDesign2 ? document.querySelector('#busswe-widget #dialog-widget-2 .container-options-input textarea') : document.getElementById('dialog-widget-area-actions-textarea');
    const buttonSubmit =  boolUseDesign2 ? document.querySelector('#busswe-widget #dialog-widget-2 .container-options-input button.button-submit-message-chat') : document.getElementById('dialog-widget-area-actions-submit');

    if( !textarea || !buttonSubmit ) return;

    textarea.disabled = status;
    buttonSubmit.disabled = status;

    const buttonPlayTempRecordAudio = document.querySelector('#busswe-widget #dialog-widget-2 .body-main-chat .container-options-input .group-option-record-finished .button-play-temp-recorded-audio');
    const buttonSendRecordAudioToChat = document.querySelector('#busswe-widget #dialog-widget-2 .body-main-chat .container-options-input .group-option-record-finished .button-send-record-audio-to-chat');
    const buttonDeleteTempRecordAudio = document.querySelector('#busswe-widget #dialog-widget-2 .body-main-chat .container-options-input .group-option-record-finished .button-delete-temp-record-audio');

    if( buttonPlayTempRecordAudio )
        buttonPlayTempRecordAudio.disabled = status;

    if( buttonSendRecordAudioToChat )
        buttonSendRecordAudioToChat.disabled = status;

    if( buttonDeleteTempRecordAudio )
        buttonDeleteTempRecordAudio.disabled = status;
}

/**
 * Elimina los mensajes de espera/escribiendo en el chat.
 */
function removeMessageWrittingInHistoryWidget()
{
    const dialogHisotryContent = boolUseDesign2 ? containerMessages2 : document.getElementById('dialog-widget-area-history-content');

    if( !dialogHisotryContent ) return;
    
    const messages = dialogHisotryContent.querySelectorAll('.message-wait-writting-widget');

    if( messages.length < 1 ) return;
    
    for (let index = 0; index < messages.length; index++) {
        const element = messages[index];
        const parent = element.parentNode;

        if( parent.classList.contains('widget-area-history-item') !== false || parent.classList.contains('message-agent') !== false )
        parent.remove();
    }

    clearTimeoutForWaitingMessage();
}

/**
 * Mostrar o ocultar el listado de agentes
 */
function showHistoryMessage()
{
    const dialogWidget = boolUseDesign2 ? 
    document.querySelector('#busswe-widget #dialog-widget-2 .container-content-widget')
    : 
    document.getElementById('dialog-widget');

    if( !dialogWidget ) return;
    
    if( boolUseDesign2 )
    {
        dialogWidget.classList.add( 'show-chat' );
    
        reset_client_contact();
        cleanUpHistoryWidget();
        setInfoAgentHistoryMessage();

        if( agent_selected && agent_selected.messages.length > 0 )
        {
            for (let index = 0; index < agent_selected.messages.length; index++) {
                
                const elementMessage = agent_selected.messages[index];
                
                insertMessageInHistoryWidget(
                    elementMessage.role == "user" ? 2 : 1,
                    elementMessage.role == "user" && elementMessage.audio ? 
                    createElementPlayAudioMessage(elementMessage.audio) : elementMessage.text, // Texto o icono play para el audio
                    elementMessage.role == "user" && elementMessage.audio, // Boolean
                    false,
                    null,
                    elementMessage.role != "user" // Si es assitant, mostrar los hyperlinks en los mensajes.
                );

            }
        }
        else
        {
            nextInProgressHistoryMessage();
        }

        muteAudioVideoBackgroundWidget2( true );
    }
    
    else
    dialogWidget.classList.remove( 'show-list-agent' );

    scrollChatToBotton();
}

/**
 * Ocultar el listado de agentes, limpia el chat y el input.
 * @returns 
 */
function hideHistoryMessage()
{
    const dialogWidget = boolUseDesign2 ?
    document.querySelector( '#busswe-widget #dialog-widget-2 .container-content-widget' )
    :
    document.getElementById('dialog-widget');

    if( !dialogWidget ) return;
    
    if( boolUseDesign2 )
    {
        dialogWidget.classList.remove('show-chat');
        showListAgents = false;

        resetProgressHistory( false );
    }
    else
    {
        dialogWidget.classList.add('show-list-agent');

        resetProgressHistory();
    }


    ttsNavigator.cancelSpeak();
}

/**
 * Seleccionar el agente para obtener su información previo a mostrar la vista historial de mensajes.
 * @param {*} agent_id
 * @returns 
 */
function selectAgent( agent_id, showChat = true, cleanChat = true, insertFirstMessageFromAgent = true )
{
    if( agent_id && agentsList.length > 0 )
    {
        let existAgent = false;
        for (let index = 0; index < agentsList.length; index++) {
            const element = agentsList[index];

            if( !existAgent && agent_id == element.id ){
                agent_selected = element;
                existAgent = true;
            }
        }

        if( !existAgent ) return;

        
        statusRegisterHistory = true;

        if( cleanChat )
        {
            reset_client_contact();
            cleanUpHistoryWidget();

        }

        if( showChat )
        {
            setInfoAgentHistoryMessage();
            showHistoryMessage();
            
            if( insertFirstMessageFromAgent )
            nextInProgressHistoryMessage();
        }

        document.querySelector('#busswe-widget #dialog-widget-2 .group-buttons-actions a.call-agent-2')
        .href = `tel:${ agent_selected.phone }`;
    }
}

/**
 * Show info agent history message.
 */
function setInfoAgentHistoryMessage()
{
    const headerHistoryMessage = boolUseDesign2 ?
    document.querySelector( '#busswe-widget #dialog-widget-2 .container-main-chat .header-main-chat' ) 
    :
    document.getElementById('dialog-widget-area-button-back');

    if( !headerHistoryMessage ) return;
    
    if( boolUseDesign2 )
    {
        headerHistoryMessage.querySelector('img.image-profile-agent-design2').src = agent_selected.icon_id;
        headerHistoryMessage.querySelector('.group-options-1 span').innerText = ( boolUseDesign2 ? '' : 'Chat: ') + agent_selected.name;
    
        const buttonsTypeA = document.querySelectorAll('#busswe-widget #dialog-widget-2 .container-main-chat .header-main-chat .group-options-2 a')

        if( buttonsTypeA.length > 0 )
        {
            // Button para una llamada telefonica.
            if( buttonsTypeA[ 0 ] )
                buttonsTypeA[ 0 ].href = `tel:${ agent_selected.phone }`;

            // Button para redireccionar a WhatsApp.
            if( buttonsTypeA[ 1 ] )
                buttonsTypeA[ 1 ].href = `https://wa.me/${ agent_selected.phone }`;
        }

        // document.querySelector('#busswe-widget #dialog-widget-2 .container-main-chat .header-main-chat .group-options-2 a')
        // .href = `tel:${ agent_selected.phone }`;
    }
    else
    {
        headerHistoryMessage.querySelector('img').src = agent_selected.icon_id;
        headerHistoryMessage.querySelector('span').innerText = 'Chat: '+agent_selected.name;
    }
}

/**
 * Restablece los valores inciales del client contact.
 */
function reset_client_contact()
{
    // client_contact = { 'name' : null, 'email' : null, 'phone' : null };

    client_contact.name = null;
    client_contact.email = null;
    client_contact.phone = null;
}

/**
 * 
 */
function resetProgressHistory( resetAgentSelected = true )
{
    statusRegisterHistory = false;
    progressRegisterHistory = 0;
    inputUser = '';

    if( resetAgentSelected )
    agent_selected = null;
    
    reset_client_contact();
    cleanUpHistoryWidget();
}

/**
 * Progreso en el historico de mensajes.
 */
async function nextInProgressHistoryMessage()
{
    const positionSystem = [ 0, 1, 2, 4, 6, 8, 9 ];
    const positionClient = [ 3, 5, 7 ];
    let message = '';
    let resultContinue = false;
    let resultValidation = false;

    do {
        toogleDisableWidgetAreaActions( true );
        resultContinue = false;

        // Verifica que el agente a conversar tenga el atribute use system en false para contnuar con el progreso,
        // o si es true tambien el valor de progress register history debe ser menor a 2 para continuar hasta los primeros valores
        if( !agent_selected.use_system || ( agent_selected.use_system && progressRegisterHistory < 2 ) )
        {
            let validation;

            switch ( progressRegisterHistory ) {
                // El sistema se presenta al agente
                case 0:
                    await sendWaitMessageToHistoryWidget( 1000 );

                    let initialMessageInVoiceUrl = '';

                    // Verifica si el agente usa el chat del sistema para utilizar el mensaje inicial unico,
                    // sino utiliza el mensaje de presetación del agente junto el nombre del agente.                    
                    if( agent_selected.use_system )
                    {
                        // Si un mensaje de un widget externo esta disponible, se utilizara como mensaje inicial.
                        if( initialMessageFromExtWidget.message != '' )
                        {
                            message = initialMessageFromExtWidget.message;

                            // Limpia el string del mensaje para evitar la repetición del mismo mensaje.
                            initialMessageFromExtWidget.message = '';

                            // Verifica si el audio del mensaje esta disponible para asignarlo junto al mensaje.
                            if( initialMessageFromExtWidget.voice != '' )
                            {
                                initialMessageInVoiceUrl = initialMessageFromExtWidget.voice;

                                // Limpia el string del audio.
                                initialMessageFromExtWidget.voice = '';
                            }
                        }
                        // Si el agente no tiene un mensaje asignado, se utiliza un mensaje por defecto.
                        else if( agent_selected.initial_message == '' )
                        {
                            message = getMessages( 0 );
                        }
                        else
                        {
                            // Se asigna el mensaje asignado en el agente
                            message = agent_selected.initial_message;
                        }

                        // Si el agente tiene un audio del mensaje inicial asignado y la variable para pasar el audio sigue con un string vacio,
                        // se asigna el audio del agente en la variable.
                        if( agent_selected.audio_initial_message != '' && initialMessageInVoiceUrl == '' )
                        initialMessageInVoiceUrl = agent_selected.audio_initial_message;
                    }
                    else
                    message = ( getMessages( 1 ) + agent_selected.name );

                    insertMessageInHistoryWidget( 1, message, null, null, initialMessageInVoiceUrl );
                    saveMessageChatInAgentSelected( 1, message );
                    break;
            
                // Muestra un mensaje predeterminado por el agente, si este lo posee.
                case 1:

                    // Verifica que el agente no tenga asignado el chat del sistema, para obtener los mensajes a mostrar en el chat.
                    if( !agent_selected.use_system )
                    {
                        await sendWaitMessageToHistoryWidget( 2000 );
                        message = getMessages( 2 );
        
                        if( messageWidgetAgent != '' )
                        {
                            message = messageWidgetAgent;
                        }
                        
                        insertMessageInHistoryWidget( 1, message );
                        saveMessageChatInAgentSelected( 1, message );
                    }

                    break;
        
                // Solicita al usuario que ingrese su nombre y apellido
                case 2:
                    await sendWaitMessageToHistoryWidget( 2000 );
                    message = getMessages( 3 );
                    insertMessageInHistoryWidget( 1, message );
                    saveMessageChatInAgentSelected( 1, message );
                    break
                
                // Validar si el usuario ingreso el nombre y apellido
                case 3:
                    validation = validateMessagesFromUser( 1, inputUser );
                    resultValidation = await checkResultValidation( validation );
                    if( resultValidation ) client_contact.name = inputUser;
                    break;
        
                // Solicita el email de usuario
                case 4:
                    await sendWaitMessageToHistoryWidget( 2000 );
                    message = getMessages( 4 );
                    insertMessageInHistoryWidget( 1, message );
                    saveMessageChatInAgentSelected( 1, message );
                    break;
        
                // Validar si el se ingreso el email
                case 5:
                    validation = validateMessagesFromUser( 2, inputUser );
                    resultValidation = await checkResultValidation( validation );
                    if( resultValidation ) client_contact.email = inputUser;
                    break;
        
                // Solicitar el telefono del usuario
                case 6:
                    await sendWaitMessageToHistoryWidget( 2000 );
                    message = getMessages( 5 );
                    insertMessageInHistoryWidget( 1, message );
                    saveMessageChatInAgentSelected( 1, message );
                    break;
                
                // Validar si el se ingreso el phone
                case 7:
                    validation = validateMessagesFromUser( 3, inputUser );
                    resultValidation = await checkResultValidation( validation );
                    if( resultValidation ) client_contact.phone = inputUser;
                    break;
    
                // Crear un paso más para validar los datos de usuario y despues enviarlo para el registro.
                // Si falla la validación, mostrar un mensaje que alguno de los datos no es valido y setear la posición dos para recolectar de nuevo
                // los datos
                case 8:
                    await sendWaitMessageToHistoryWidget( 2000 );
                    message = getMessages( 6 );
                    insertMessageInHistoryWidget( 1, message );
                    saveMessageChatInAgentSelected( 1, message );
                    
                    validation = validateAllInputUser();
    
                    if( validation )
                    resultValidation = await sendClientContactToAgent();
                    break;
        
                // Mensaje indicando que esta listo, solo debe seleccionar el medio para contactarlo
                case 9:
                    await sendWaitMessageToHistoryWidget( 2000 );
                    message = getMessages( 7 );
                    insertMessageInHistoryWidget( 1, message );
                    saveMessageChatInAgentSelected( 1, message );
                    insertAgentContact();

                    // Restablece los valores del client
                    reset_client_contact();
                    break;
            }
    
            // Valida que la posición actual son mensajes del sistema para permitir bucle
            // o si la posición es del usuario y se debe validar que la información que ingreso sea valida.
            if( positionSystem.includes( progressRegisterHistory ) || (positionClient.includes( progressRegisterHistory ) && resultValidation) ){
                progressRegisterHistory++;
    
                if( !positionClient.includes(progressRegisterHistory) )
                resultContinue = true;
    
                // Habilitar el input del usuario cuando requiera ingresar información
                else /* ( positionClient.includes( progressRegisterHistory ) ) */
                {
                    toogleDisableWidgetAreaActions( false );
                }
    
                if( progressRegisterHistory >= 8 )
                toogleDisableWidgetAreaActions( true );
            }
            else if( positionClient.includes( progressRegisterHistory ) && !resultValidation )
            {
                toogleDisableWidgetAreaActions( false );
            }

        }
        else if ( agent_selected.use_system && progressRegisterHistory > 1 )
        {
            toogleDisableWidgetAreaActions( false );
        }
        
    } while ( resultContinue );
    
    inputUser = '';
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
async function checkResultValidation( data )
{
    // Si no paso la validación, se envia un mensaje en el historico para indicar el error de validación.
    if( data.status ){
        return true;

    }else{
        await sendWaitMessageToHistoryWidget( 2000 );
        insertMessageInHistoryWidget( 1, data.message );
        return false;
    }
}

/**
 * 
 * @param {*} typeInput 
 * @param {*} input 
 * @returns 
 */
function validateMessagesFromUser( typeInput, input )
{
    let result = { 'status' : true, 'message' : '', 'value' : input };

    // Validaciones

    // El campo no puede estar vacio

    // No puede superar el maximo de caracteres
    let validationFromMaxCharacters = validationMaxCharacters( typeInput, input );
    if( !validationFromMaxCharacters.status )
    {
        result.status = false;
        result.message = validationFromMaxCharacters.message;
        return result;
    }

    // Debe manterner el formato esperado
    let validationFromRegex = validationRegex( typeInput, result.value );
    if( !validationFromRegex.status )
    {
        result.status = false;
        result.message = validationFromRegex.message;
        return result;
    }

    return result;
}

/**
 * Validación en el maximo de caracteres permitidos
 * @param {*} typeInput 
 * @param {*} input 
 * @returns 
 */
function validationMaxCharacters( typeInput, input )
{
    let result = { 'status' : true, 'message' : '' };
    let limitMinCharacters = 1;
    let limitMaxCharacters = 45;

    // Estableciendo los limites de caracteres en base al tipo del input
    if( typeInput == 2 ){
        limitMinCharacters = 5;
        limitMaxCharacters = 45;

    }else if( typeInput == 3 )
    {
        limitMinCharacters = 9;
        limitMaxCharacters = 14;
    }

    // Validando los limites de caracteres en el input
    if( input.length < limitMinCharacters || input.length > limitMaxCharacters )
    {
        switch ( languagePage ) {
            case 'en':
                if( typeInput == 3 )
                result.message = "The phone number is not correct, please enter it again.";

                else
                result.message = "Number of characters is not valid. Minimun "+limitMinCharacters+" and maximun "+limitMaxCharacters+".";
                break;
        
            default:
                if( typeInput == 3 )
                result.message = "El número de teléfono no es correcto, ingréselo nuevamente.";

                else
                result.message = "Cantidad de caracteres no es válido. Mínimo "+limitMinCharacters+" y maximo "+limitMaxCharacters+".";
                break;
        }
        result.status = false;
        return result;
    }

    return result;
}

/**
 * Validación usando expresiones regulares
 * @param {*} type 
 * @param {*} value 
 * @returns 
 */
function validationRegex( type, value )
{
    let result = { 'status' : true, 'message' : '' };

    if( type == 1 && /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]{1,45}\s[a-zA-ZáéíóúÁÉÍÓÚñÑ]{1,45}$/.exec(value) === null )
    {
        switch ( languagePage ) {
            case 'en':
                result.message = "Must be a first and last name";
                
                break;
        
            default:
                result.message = "Debe ser un nombre y un apellido";

                break;
        }
        result.status = false;
        return result;
    }
    else if( type == 2 && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.exec(value) === null )
    {
        switch ( languagePage) {
            case 'en':
                result.message = "Email format is not valid";
                
                break;
        
            default:
                result.message = "Formato del email no es valido";

                break;
        }
        result.status = false;
        return result;
    }
    else if( type == 3 ){

        if( !checkStringForOnlyCharactersForPhoneFied( value ) )
        {
            switch ( languagePage ) {
                case 'en':
                    // result.message = "Phone format is invalid";
                    result.message = "I need a valid phone number.";
                    
                    break;
            
                default:
                    // result.message = "Formato del telefono no es valido";
                    result.message = "Necesito un número de teléfono válido.";
    
                    break;
            }
            result.status = false;
            return result;
        }

        if( /^(\+[0-9]{1,2})?[0-9]{9,11}$/.exec(value) === null )
        {
            switch ( languagePage ) {
                case 'en':
                    result.message = "Phone format is invalid";
                    
                    break;
            
                default:
                    result.message = "Formato del telefono no es valido";
    
                    break;
            }
            result.status = false;
            return result;
        }
    }

    return result;
}

/**
 * Valida toda la información ingresa por el usuario
 * @returns 
 */
function validateAllInputUser()
{
    let result = false;

    if( client_contact )
    {
        let resultValidation = [];
        const input = [ 
            { 'typeInput' : 1, 'value' : client_contact.name },
            { 'typeInput' : 2, 'value' : client_contact.email },
            { 'typeInput' : 3, 'value' : client_contact.phone },
        ];
        
        // Validando el name, email y phone
        for (let index = 0; index < input.length; index++) {
            const element = input[index];
            
            const resultInput = validateMessagesFromUser( element.typeInput, element.value );
            resultValidation.push( resultInput.status );
        }

        // Verifica los resultados, si existe por lo menos un false se restablecera el progreso a casi el inicio
        if( resultValidation.includes( false ) ){
            resetProgressHistoryByFailedValidation();

        }else{
            // No existe ninungo false en los resultados.
            result = true;
        }
    }

    return result;
}

/**
 * 
 */
function resetProgressHistoryByFailedValidation()
{
    const message = 'Encontre algunos datos que no son correctos, se deben corregir';

    insertMessageInHistoryWidget( 1, message );

    saveMessageChatInAgentSelected( 1, message );

    progressRegisterHistory = 1;
}

/**
 * Función para remover los espacios extras
 * @param {*} string 
 * @param {*} removeBreakLine 
 * @returns 
 */
function removeExtraWhiteSpaceInString( string, removeBreakLine = false )
{
    if( removeBreakLine )
    string = string.replace( '\n', ' ' );

    return string.replace(/ +(?= )/g,'').trim();
}

/**
 * Envia la información del usuario al agente
 * @returns 
 */
async function sendClientContactToAgent()
{
    const url = widgetUrlBase + 'v1/widget/contact_agent';

    const data = {
        'widget_id' : widgetIdBase,
        'agent_id' : agent_selected.id,
        'name' : client_contact.name,
        'email' : client_contact.email,
        'phone' : client_contact.phone,
        'ccccm' : customerContactCycleCampaingMarketing
    };

    const fetchWidgetAgentPost = await fetchAgentsWidget( 'POST', url, data );

    // Verifica que el fecth este correcto
    if( fetchWidgetAgentPost.status ){
        // console.log('Paso el fetch', fetchWidgetAgentPost.result);
        
        // Restablece los valores del client
        // reset_client_contact();

        return true;
    }
    else
    {
        console.error('Fallo el fetch');

        resetProgressHistoryByFailedValidation();

        return false;
    }
}

/**
 * Envia mensaje del usuario al agente en el sistema.
 * @returns 
 */
async function sendMessageClientToAgentInSystem( message = "", file = null, typeFile = 0 )
{
    sendWaitMessageToHistoryWidget( 15000 );
    toogleDisableWidgetAreaActions( true );

    const url = widgetUrlBase + 'v1/widget/agents/message';

    let data = null;
    
    if( file )
    {
        data = new FormData();
        
        if( customerContactCycleCampaingMarketing )
        data.append('ccccm', customerContactCycleCampaingMarketing);
    
        data.append('user_client_token', userClientToken);
        data.append('widget_id', widgetIdBase);
        data.append('agent_id', agent_selected.id);
        data.append('message', message);
        data.append('type_file', typeFile);
        data.append('file', file);
    }
    else
    {
        data = {
            'user_client_token' : userClientToken,
            'widget_id' : widgetIdBase,
            'agent_id' : agent_selected.id,
            'message' : message,
            'ccccm' : customerContactCycleCampaingMarketing
        };
    }

    const fetchWidgetAgentPost = await fetchAgentsWidget( 'POST', url, data );

    toogleDisableWidgetAreaActions( false );

    // Verifica que el fecth este correcto
    if( fetchWidgetAgentPost.status )
    {
        // console.log("recibi respuesta del fetch sobre el mensaje", fetchWidgetAgentPost.result);

        removeMessageWrittingInHistoryWidget();

        insertMessageInHistoryWidget( 
            1, fetchWidgetAgentPost.result.message, false, 
            null, fetchWidgetAgentPost.result.voice_url,
            fetchWidgetAgentPost.result.enable_hyperlinks
        );

        saveMessageChatInAgentSelected( 
            1, fetchWidgetAgentPost.result.message, 
            null, fetchWidgetAgentPost.result.enable_hyperlinks
        );

        return true;
    }
    else
    {
        console.error('Fallo el fetch');

        removeMessageWrittingInHistoryWidget();

        insertMessageInHistoryWidget( 1, "Can't contact to Agent" );

        saveMessageChatInAgentSelected( 1, "Can't contact to Agent" );

        // resetProgressHistoryByFailedValidation();

        return false;
    }
}

/**
 * 
 * @param {*} option 
 * @param {*} param 
 * @returns 
 */
function getMessages( option, param = {} )
{
    let message = '';

    const langAgent = agent_selected?.lang_id == 1 ? 'en' : 'es';

    switch ( option ) {
        case 0:
            if( langAgent == 'en' )
            message = 'Greetings! I am happy to be able to serve you.';
            else
            message = '¡Saludos! Estoy feliz de poder atenderte.';
        
            break;

        case 1:
            if( langAgent == 'en' )
            message = 'Hi, I am ';
            else
            message = 'Hola, soy ';

            break;

        case 2:
            if( langAgent == 'en' )
            message = 'I am here to help you in whatever you need, but first I need information to prepare myself';
            else
            message = 'Estoy aqui para ayudarlo en lo que necesite, pero antes necesito información para prepararme';

            break;
        
        case 3:
            if( langAgent == 'en' )
            message = 'Enter your first and last name';
            else
            message = 'Indique su nombre y apellido';

            break;
        
        case 4:
            if( langAgent == 'en' )
            message = 'Now your Email';
            else
            message = 'Ahora su Email';

            break;
        
        case 5:
            if( langAgent == 'en' )
            message = 'Finally your phone';
            else
            message = 'Finalmente su télefono';

            break;
        
        case 6:
            if( langAgent == 'en' )
            message = "Wait a few moments I'm reviewing your information";
            else
            message = 'Espere unos momentos que estoy revisando su información';

            break;
        
        case 7:
            if( langAgent == 'en' )
            message = "Done, now you can contact me directly by one of these means:";
            else
            message = 'Listo, ahora puede contactame directamente por uno de estos medios:';

            break;
        
        case 8:
            if( langAgent == 'en' )
            message = "Online";
            else
            message = 'En Línea';

            break;

        case 9:
            if( Object.keys( param ).length > 0 )
            {
                if( langAgent == 'en' )
                message = "Hello "+param['agent']+", "+"I'm "+param['client']+", I would like more information.";
                else
                message = 'Hola '+param['agent']+', '+'Soy '+param['client']+', quisiera más información.';
            }

            break;

        case 10:
            if( languagePage == 'en' )
            message = "Type your message";
            else
            message = 'Escribe tu mensaje';
            
            break;

        case 11:
            if( languagePage == 'en' )
            message = "Agents";
            else
            message = 'Agentes';
            
            break;

        case 12:
            if( languagePage == 'en' )
            message = "We're Online!";
            else
            message = 'Estamos en línea';
            
            break;

        case 13:
            if( languagePage == 'en' )
            message = "Connect live with our agents";
            else
            message = 'Conéctese en vivo con nuestros agentes';
            
            break;

        case 14:
            if( languagePage == 'en' )
            message = "Let's talk";
            else
            message = 'Hablemos';
            
            break;

        case 15:
            if( languagePage == 'en' )
            message = "Call Us";
            else
            message = 'Llámanos';
            
            break;

        case 16:
            if( languagePage == 'en' )
            message = "Book a Meeting";
            else
            message = 'Reserve una reunión';
            
            break;

        case 17:
            if( languagePage == 'en' )
            message = "Visit this page";
            else
            message = 'Visita esta pagina';
            
            break;

        case 18:
            if( languagePage == 'en' )
            message = "Agents";
            else
            message = 'Agentes';
            
            break;

        case 19:
            if( languagePage == 'en' )
            message = "Start a Call";
            else
            message = 'Llamar';
            
            break;

        case 20:
            if( languagePage == 'en' )
            message = "WhatsApp";
            else
            message = 'WhatsApp';
            
            break;

        case 21:
            if( languagePage == 'en' )
            message = "Book a Meeting";
            else
            message = 'Reservar';
            
            break;

        case 22:
            if( languagePage == 'en' )
            message = "Time Limit";
            else
            message = 'Límite de tiempo';
            
            break;

        case 23:
            if( languagePage == 'en' )
            message = "Voice Message Result";
            else
            message = 'Resultado del Mensaje de Voz';
            
            break;

        case 24:
            if( languagePage == 'en' )
            message = "Request Call";
            else
            message = 'Solicitar Llamada';
            
            break;
    
    }

    return message;
}

/**
 * Detiene el progreso del widget por unos ms
 * @param {*} ms 
 * @returns 
 */
function sleepWidgetProgress(ms) {
    return new Promise(
        resolve => {
            clearTimeoutForWaitingMessage();

            timerToRemoveWaitingMessage = setTimeout(resolve, ms);
        }
    );
}

function clearTimeoutForWaitingMessage()
{
    if( timerToRemoveWaitingMessage )
    {
        clearTimeout( timerToRemoveWaitingMessage );
        timerToRemoveWaitingMessage = null;
    }
}

/**
 * 
 * @param {*} time 
 * @returns 
 */
async function sendWaitMessageToHistoryWidget( time )
{
    insertMessageInHistoryWidget( 1, 'wait', false, true);

    await sleepWidgetProgress(time);
    
    removeMessageWrittingInHistoryWidget();

    return;
}

/**
 * Verifica si un string tiene los valores para el campo phone
 * @param {*} str 
 * @returns 
 */
function checkStringForOnlyCharactersForPhoneFied( str ) {
    return /^[+0-9]*$/.test(str);
}

// function getCurrentDateTime()
// {
//     // create a new `Date` object
//     const now = new Date();

//     // get the current date and time as a string
//     const currentDateTime = now.toLocaleString();

//     return currentDateTime;
// }




///////////////////////////////////////////////////////////
// Diseño 2 - Widget Agentes
let boolUseDesign2 = false;
let containerMessages2 = null;
let videoBackgroundWidget2 = null;
let activeAudioVideoBackgroundWidget2 = false;
let buttonToWidgetScheduleAppointment = null;
let buttonToWidgetCallAgent = null;

let firstActionUser = false; // Estado de la primera interacción del usuario, para despues poder habilitar el audio del video de fondo.

function initVariablesInDesing2Widget()
{
    boolUseDesign2 = false;
    containerMessages2 = document.querySelector( '#busswe-widget #dialog-widget-2 .container-chat-messages' );
    videoBackgroundWidget2 = document.querySelector( '#busswe-widget #dialog-widget-2 .container-content-widget .main-content-widget .background-media video' );
    buttonToWidgetScheduleAppointment = document.querySelector( '#widget-button-container-schedule-appointment > a' );
    buttonToWidgetCallAgent = document.querySelector( '#myBtn-widget-call-agent' );

    // Inicializa los elementos del sub modulo Record Audio del Widget.
    initElementsForSubModuleRecordAudio();

    const buttonOpenWidget = document.querySelector( '#busswe-widget details > summary' );
    
    if( buttonOpenWidget )
    buttonOpenWidget.addEventListener( 'click', toggleShowContentWidgetAgents );
    
}

function checkIfUsingDesign2()
{
    const widgetMain = document.getElementById('busswe-widget');

    if( !widgetMain ) return;

    boolUseDesign2 = widgetMain.classList.contains( 'widget-agent-desing-2' );
}

/**
 * Destaca al agente seleccionado o dejar de destacar a todos los agentes.
 * @param {*} elementItemId 
 * @returns 
 */
function hightlightAgentSelected( elementItemId )
{
    if( !boolUseDesign2 ) return;
    
    const listAgentItems = document.querySelectorAll( '#busswe-widget #dialog-widget-2 .list-group-agents-icons li' );

    if( listAgentItems.length < 1 ) return;
    
    let isThereAnAgenteActive = false;
    
    // Remueve la clase que destaca al agente de todos los disponibles.
    for (let index = 0; index < listAgentItems.length; index++) {
        const elementItemAgent = listAgentItems[index];

        // Comprueba por el id si es el mismo id del elemento y el elemento no tenga la clase active para destacar al agente.
        if( elementItemAgent.id == elementItemId && !elementItemAgent.classList.contains( 'active-agent' ) )
        {
            // El item agent se le agrega la clase para destacar al agente
            elementItemAgent.classList.add( 'active-agent' );

            isThereAnAgenteActive = true;
        }
        // Si el elemento tiene la clase active se elimina la clase del elemento.
        else if( elementItemAgent.classList.contains( 'active-agent' ) )
        {
            elementItemAgent.classList.remove( 'active-agent' );
        }

    }

    toggleShowOptionToContactAgent( !isThereAnAgenteActive );
}

/**
 * Muestra o oculta las opciones (botones) que permiten mostrar la vista del chat con un agente.
 * @param {*} optionShow 
 * @returns 
 */
function toggleShowOptionToContactAgent( optionShow = false )
{
    const widget2 = document.querySelector( '#busswe-widget #dialog-widget-2');

    if( !widget2 ) return;

    widget2.classList.toggle( 'hide-options-to-contact-agent', optionShow );
}

/**
 * Reproducir el video cada cierto tiempo.
 * @returns 
 */
function setSecuenceToPlayVideoBackgroundWidget2()
{
    if( !boolUseDesign2 || !videoBackgroundWidget2 ) return;

    if( videoBackgroundWidget2.getAttribute('src') == "" ) return;

    // console.log("src video bg", videoBackgroundWidget2, videoBackgroundWidget2.getAttribute('src'));

    const toggleShowVideo = function ( showVideo = false ) {
        videoBackgroundWidget2.classList.toggle( 'show-background-video', showVideo );
    }

    const playVideo = () => {
        if( videoBackgroundWidget2.getAttribute('src') != "" )
        {
            // console.log("play video bg", videoBackgroundWidget2.getAttribute('src'));

            if( videoBackgroundWidget2.currentTime >= videoBackgroundWidget2.duration  )
            videoBackgroundWidget2.currentTime = 0;

            videoBackgroundWidget2.play();
            toggleShowVideo( true );
        }
    }

    const pauseVideo = () => {
        if( videoBackgroundWidget2.getAttribute('src') == "" || !videoBackgroundWidget2.classList.contains( "show-background-video" ) )
        return;
        
        if( videoBackgroundWidget2.currentTime > 0 && videoBackgroundWidget2.currentTime < videoBackgroundWidget2.duration )
        {
            videoBackgroundWidget2.pause();
            // toggleShowVideo( true );
        }
    }

    const togglePlayVideo = () => {

        if( videoBackgroundWidget2.classList.contains( "show-background-video" ) )
        videoBackgroundWidget2.paused ? playVideo() : pauseVideo();

    }

    const videoEnded = () => {
        toggleShowVideo( false );
        videoBackgroundWidget2.pause();
        videoBackgroundWidget2.currentTime = 0;

        setTimerToWaitBeforePlayVideo( 30000 ); // Esperar 30 segundos antes de volver a reproducir el video.
    }

    const setTimerToWaitBeforePlayVideo = ( timeToWait = 15000 /* 15 seg por defecto */ ) => {
        setTimeout( playVideo, timeToWait );
    }

    setTimerToWaitBeforePlayVideo( 5000 ); // Reproduce el video despues de 5 Segundos.

    videoBackgroundWidget2.addEventListener('ended', videoEnded);

    videoBackgroundWidget2.addEventListener('click', togglePlayVideo);

    enableAudioForVideoBackgroundInWidgetOnlyIfVisible();
}

/**
 * Activa o Silencia el audio del video de fondo del widget 2.
 * @param {*} status 
 */
function muteAudioVideoBackgroundWidget2( status = true )
{
    if( !boolUseDesign2 || !videoBackgroundWidget2 || !activeAudioVideoBackgroundWidget2 ) return;

    if( videoBackgroundWidget2.getAttribute('src') == "" ) return;

    videoBackgroundWidget2.muted = status;
}

/**
 * Establece un evento en el body para obtener el primer click de usuario, 
 * se utilizara para saber si habilitar el audio del video de fondo dentro del widget agentes.
 */
function getFirstActionFromUserForEnableAudioInVideoBackground()
{
    // Verifica que la variable no sea true.
    if( firstActionUser ) return;

    // Función para cambiar el estado de la variable y remover el evento click del body.
    const setFirstActionUser = () => {
        
        if( firstActionUser ) return;

        firstActionUser = true;

        enableAudioForVideoBackgroundInWidgetOnlyIfVisible();

        document.querySelector('body').removeEventListener('click', () => {
            setFirstActionUser();
        });
    }

    // Asigando evento click en el body.
    document.querySelector('body').addEventListener('click', () => {
        setFirstActionUser();
    });
}

/**
 * Habilita el audio del video de fondo del widget agentes, solo si el widget esta visible.
 */
function enableAudioForVideoBackgroundInWidgetOnlyIfVisible()
{
    // Verifica que la primera acción sa true, este activo el segundo diseño que tiene el video de fondo
    // y que haya un video de fondo activo.
    if( !firstActionUser || !boolUseDesign2 || !videoBackgroundWidget2 || !activeAudioVideoBackgroundWidget2 ) return;

    // Verificar que este visible el menu/contenido del widget agentes.
    const widgetAgents = document.querySelector( '#busswe-widget details' );

    // Verifica que el widget agentes exista y que este visible.
    if( !widgetAgents || ( widgetAgents && widgetAgents.open == false ) ) return;

    // Verificar que actualmente en el widget se esta mostrando la lista de agentes.
    const mainView = widgetAgents.querySelector( '#dialog-widget-2 > .container-content-widget:not( .show-chat )' );

    if( !mainView ) return;

    muteAudioVideoBackgroundWidget2( false );
}

/**
 * Obtiene el estado del atributo open de details, que permite ver el contenido del widget.
 * Con el estado del open, se ejecutaran otras funciones dependiendo del estado.
 */
function toggleShowContentWidgetAgents()
{
    const buttonDetails = document.querySelector( '#busswe-widget details' );

    if( !buttonDetails ) return;

    // Si es true, vibsible el contenido del widget
    setTimeout( () => {

        if( buttonDetails.open )
        {
            enableAudioForVideoBackgroundInWidgetOnlyIfVisible();
        }
        else
        {
            // false, no es visible el contenido del widget.
            muteAudioVideoBackgroundWidget2( true );
        }

    }, 200);
}

////////////////////////////////////////////////////////////////////////////
/* Gestiona la grabación de voz para ser enviado como mensaje al agente */
let tempRecordedAudioAudioMessage = { url: '', blob: null, file: null, type: '' };
let iconPlayAudio = `<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path d="M10 16.5v-9l6 4.5M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2"/></svg>`;
let iconPlayAudio2 = `<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path d="M8 5.14v14l11-7z"/></svg>`;
let iconStopAudio = `<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path d="M18 18H6V6h12z"/></svg>`;
const playerAudioMessage = new Audio;
let timerRecordAudio;
const maxTimeRecordAudio = 60;


/**
 * Crea el contenido de un mensaje para el chat, con un icono de play para reproducir audios.
 * @param {*} urlAudioMessage 
 * @returns 
 */
function createElementPlayAudioMessage( urlAudioMessage )
{
    const itemSpan = document.createElement('span');
    itemSpan.classList.add( 'item-message-audio' );
    itemSpan.innerHTML = iconPlayAudio;
    itemSpan.dataset.urlAudioMessage = urlAudioMessage;
    itemSpan.style.cursor = 'pointer';

    // Asignando evento click en el mensaje para reproducir el audio grabado.
    itemSpan.addEventListener('click', ( event ) => {

        // Verifica que el mensaje tiene asignado una dataset con el url del audio.
        if( event.currentTarget.dataset.urlAudioMessage )
        {
            // Reproduce el audio asignado en el mensaje.
            const isPlayingAudio = playAudioMessage( event.currentTarget.dataset.urlAudioMessage );

            changeIconToStopInAllMessagesAudio();
            
            if( isPlayingAudio )
                itemSpan.innerHTML = iconStopAudio;
        }

    });

    return itemSpan;
}

/**
 * Crea un item mensaje para insertalo en el historial de mensajes del usuario con el agente.
 * @returns 
 */
function sendMessageAudioToChat()
{
    if( !tempRecordedAudioAudioMessage || ( tempRecordedAudioAudioMessage && tempRecordedAudioAudioMessage.url == "" ) ) return;

    // Verifica que un agente este seleccionado y este habilitado con el chatbot.
    if( !agent_selected || ( agent_selected && !agent_selected.use_system ) ) return;

    toogleDisableWidgetAreaActions( true );

    const itemSpan = createElementPlayAudioMessage( tempRecordedAudioAudioMessage.url );

    // Inserta un nuevo mensaje en el chat, un mensaje con un icono para reprodcuir el audio grabado.
    insertMessageInHistoryWidget( 2, itemSpan, true );

    saveMessageChatInAgentSelected( 2, "", tempRecordedAudioAudioMessage.url);

    // Enviar el audio como mensaje al agente.
    sendMessageClientToAgentInSystem( "", tempRecordedAudioAudioMessage.file, 1 )
    .then( result => {

        // Si el resultado de la promesa es true, procede a ocultar la sección record audio y eliminar el audio grabado.
        if( result )
        {
            // Oculta la vista que gestiona la grabacación de audios.
            toggleShowViewRecordingAudio( false, false );
        
            // Elimina el audio grabado.
            deleteRecordedAudio();            
        }

        // Si el resultado es false, se mantiene el archivo por si el usuario quiere 
        // realizar otro intento para enviar el audio.
    } );

}

/**
 * 
 */
function saveMessageChatInAgentSelected( roleId = 1, messageChat = null, audioUrl = null, useHyperLinks = false )
{
    if( !agent_selected || !roleId || !messageChat ) return;

    if( agentsList.length < 1 ) return;


    for (let index = 0; index < agentsList.length; index++) {
        const elementAgent = agentsList[index];
        
        if( elementAgent.id == agent_selected.id )
        {
            const newMessage = { 
                text: messageChat, role: roleId == 1 ? 'assistant' : 'user', 
                audio: audioUrl, useHyperLinks: useHyperLinks
            };

            agentsList[ index ].messages.push( newMessage );
        }
    }       

}


/**
 * Reproduce la url del audio mensaje del chat.
 * @param {*} url 
 */
function playAudioMessage( url = "" )
{
    if( url == "" || url == undefined || !url ) return;

    const newUrlAudio = url;

    // console.log( playerAudioMessage.src == newUrlAudio, playerAudioMessage.src, newUrlAudio )

    if( playerAudioMessage.played )
    {
        playerAudioMessage.pause();
        playerAudioMessage.currentTime = 0;
    }

    if( playerAudioMessage.src == newUrlAudio )
    {
        playerAudioMessage.src = "";
        return;
    }

    playerAudioMessage.src = newUrlAudio;
    playerAudioMessage.play();

    return true;
}

/**
 * Cambia el contenido de un elemento por un SVG Icon de Stop.
 * Se utiliza para cambiar los iconos Play que reproducen un audio mensaje del chat.
 */
function changeIconToStopInAllMessagesAudio()
{
    const allAudioMessages = document.querySelectorAll( '#busswe-widget #dialog-widget-2 .body-main-chat .item-message-audio' );

    if( allAudioMessages.length < 1 ) return;

    for (let index = 0; index < allAudioMessages.length; index++) {
        const element = allAudioMessages[index];
        
        if( element.innerHTML != iconPlayAudio && element.innerHTML != iconPlayAudio2 )
        element.innerHTML = element.classList.contains('button-play-temp-recorded-audio') ? iconPlayAudio2 : iconPlayAudio;
    }
}

/**
 * Pide los permisos para utilizar el microfono para grabar el audio, 
 * mostrara una interface para indicar el tiempo de grabación y 
 * el boton para detener la grabación.
 */
function startRecordAudio()
{
    // Verifica si el timer esta activo no continua.
    if( timerRecordAudio ) return;

    // Ejecuta la grabación del audio, si retorna un true no hubo problemas al solicitar el permiso del microfono.
    recordAudioMic.startRecordAudio().then( result => {

        if( result )
        {
            // console.log('Permiso de grabación aceptado');

            // Muestra la sección paa indicar que comenzo la grabación del audio.
            toggleShowViewRecordingAudio( true, false );

            // Cambia el texto para indicar que esta grabando el audio.
            // toggleStatusRecordAudio( true );

            // Muestra el timer para indicar que tiempo que tiene de grabación.
            toggleSetTimerForRecordingAudio( true );
        }
        else
        alert( 'Permission to use the microphone was not given.' );

    } );
}

async function stopRecordAudio()
{
    // console.log('detener grabación');

    // Verifica si el timer no esta activo, no continua.
    if( !timerRecordAudio ) return;

    // Detiene la grabación del microfono y espera el Blob que es resultado de la grabación.
    const newAudioRecorded = await recordAudioMic.finishRecordAudio();

    if( newAudioRecorded )
    {
        // console.log( "Un nuevo audio", newAudioRecorded );

        // Almacena el blob en la variable de audio grabado temporal.
        tempRecordedAudioAudioMessage.blob = newAudioRecorded;
        tempRecordedAudioAudioMessage.type = newAudioRecorded.type;
        
        tempRecordedAudioAudioMessage.file = new File( 
            [ newAudioRecorded ], 
            'recordAudioCustomerToAgent_'+( new Date().getTime() )+( newAudioRecorded.type == 'audio/webm' ? '.webm' : '.wav'),
            { type: newAudioRecorded.type, lastModified: new Date().getTime() }
        );


        // Con el Blob del audio se crea un Objecto URL para asignarlo en el src de la etiqueta audio,
        // para dar prueba del audio grabado.
        tempRecordedAudioAudioMessage.url = URL.createObjectURL( newAudioRecorded );

        // Elimina el objecto url del Blob.
        window.URL.revokeObjectURL( newAudioRecorded );
    }

    // Elimina el timer.
    toggleSetTimerForRecordingAudio( false );

    // Oculta los textos sobre el estado de la grabación.
    // toggleStatusRecordAudio( false );
    toggleShowViewRecordingAudio( true, true );
}

/**
 * Elimina el audio grabado y retorna a la vista base del chat.
 */
function deleteRecordedAudio()
{
    // Verifica que exista un audio grabado en la variable.
    if( tempRecordedAudioAudioMessage && ( tempRecordedAudioAudioMessage.url == "" || tempRecordedAudioAudioMessage.blob == null ) )
    {
        // Verifica si hay un audio reproduciendo y si ese audio es el mismo del audio grabado a eliminar.
        if( playerAudioMessage.played && playerAudioMessage.src == tempRecordedAudioAudioMessage.url )
        {
            // Detiene el audio, restablece el tiempo de reproducción y remueve el src del audio.
            playerAudioMessage.pause();
            playerAudioMessage.currentTime = 0;
            playerAudioMessage.src = "";
        }
    
        // Restablece los valores por defectos de la variable que tiene información del audio grabado.
        tempRecordedAudioAudioMessage.blob = null;
        tempRecordedAudioAudioMessage.file = null;
        tempRecordedAudioAudioMessage.url = "";
        tempRecordedAudioAudioMessage.type = "";
    }

    // Oculta la vista que gestiona la grabación del audio.
    toggleShowViewRecordingAudio( false, false );
}

/**
 * 
 */
function toggleShowViewRecordingAudio( status = false, statusFinished = false )
{
    const containerView = document.querySelector( '.container-options-input .container-options-record-audio' );

    if( !containerView ) return;

    containerView.classList.toggle( 'show-option-process', status && !statusFinished );

    containerView.classList.toggle( 'show-option-finished', status && statusFinished );
}

/**
 * 
 * @param {*} status 
 */
function toggleSetTimerForRecordingAudio( status )
{
    // Obtiene el elemento que muestra con texto que sirve como un contador.
    const timer = document.querySelector('.container-options-record-audio .group-option-record-in-process .timer-recording-audio');

    

    // Función para cambiar el texto de un span, utilizando el valor del parametro.
    const setTimeInTimer = ( time = 0 ) => {

        const labelTimer = languagePage == 'en' ? 'Timer' : 'Tiempo';

        timer.textContent =  `${labelTimer}: ${time}s`;
    }


    // Si el status es true, inicializa el contador con 0s y 
    // activa un setInterval que se ejecutara cada segundo para actualizar el tiempo del timer.
    if( status )
    {
        let counter = 0 // Contador para simular los segundos en el timer.
        
        setTimeInTimer( counter );

        timerRecordAudio = setInterval( () => {

            if( ++counter >= maxTimeRecordAudio )
            stopRecordAudio();

            setTimeInTimer( counter );
            
        }, 1000 );
    }
    else
    {
        setTimeInTimer( 0 ); // Restablece el timer a 0s.

        clearInterval( timerRecordAudio ); // Desactiva el setInterval.

        timerRecordAudio = null; // Elimina el contenido de la variable que tenia al setInterval timer.
    }
}

/**
 * Inicializa los elementos que conforman el sub modulo Record Audio del Widget.
 */
function initElementsForSubModuleRecordAudio() 
{
    const buttonPlayTempRecordAudio = document.querySelector('#busswe-widget #dialog-widget-2 .body-main-chat .container-options-input .group-option-record-finished .button-play-temp-recorded-audio');
    const buttonSendRecordAudioToChat = document.querySelector('#busswe-widget #dialog-widget-2 .body-main-chat .container-options-input .group-option-record-finished .button-send-record-audio-to-chat');
    const buttonDeleteTempRecordAudio = document.querySelector('#busswe-widget #dialog-widget-2 .body-main-chat .container-options-input .group-option-record-finished .button-delete-temp-record-audio');

    const buttonStartRecordAudio = document.querySelector('#busswe-widget #dialog-widget-2 .body-main-chat .container-options-input .options-button-to-send-message .button-start-record-voice-message-chat');
    const buttonStopRecordAudio = document.querySelector('#busswe-widget #dialog-widget-2 .body-main-chat .container-options-input .container-options-record-audio .group-option-record-in-process > button');

    if( buttonSendRecordAudioToChat )
        buttonSendRecordAudioToChat.addEventListener('click', () => { sendMessageAudioToChat(); });

    if( buttonDeleteTempRecordAudio )
        buttonDeleteTempRecordAudio.addEventListener('click', () => { deleteRecordedAudio(); });

    if( buttonPlayTempRecordAudio )
        buttonPlayTempRecordAudio.addEventListener('click', () => { 

            if( tempRecordedAudioAudioMessage && tempRecordedAudioAudioMessage.url != "" )
            {
                const isPlayingAudio = playAudioMessage( tempRecordedAudioAudioMessage.url );
                
                changeIconToStopInAllMessagesAudio();
                
                if( isPlayingAudio )
                    buttonPlayTempRecordAudio.innerHTML = iconStopAudio;
            }

        });

    if( buttonStartRecordAudio )
        buttonStartRecordAudio.addEventListener('click', () => { startRecordAudio() });

    if( buttonStopRecordAudio )
        buttonStopRecordAudio.addEventListener('click', () => { stopRecordAudio() });

    playerAudioMessage.addEventListener('ended', () => {
        playerAudioMessage.currentTime = 0;
        playerAudioMessage.src = "";

        changeIconToStopInAllMessagesAudio();
    })
}


/* Fin - Gestion en la grabación de voz para ser enviado como mensaje al agente */
//////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////
// Inicio - Cookies para identificar al usuario.

const nameUniqueUserCookie = 'uniqueUserInWidgetAgents'; // Nombre de la Cookie
// let uniqueUserCookie; // Valor que almacena la Cookie.

/**
 * Consulta inicial para obtener el valor de una Cookie que identifica al usuario del widget.
 */
function initGetValueCookieForUniqueUser()
{
    // Al cargar la pagina, verificar si existe un Cookie llamado "uniqueUserInWidgetAgents",
    // sirve para identificar al usuario para mantener su conversaciones con los agentes.
    const tempCookieUniqueUser = Cookie.get( nameUniqueUserCookie );

    if( tempCookieUniqueUser && tempCookieUniqueUser != "" )
    {
        // uniqueUserCookie = tempCookieUniqueUser;
        userClientToken = tempCookieUniqueUser;
    }
}

/**
 * Asigna un valor a la variable y Cookie, para almacenar la identificación del usuario.
 * 
 */
function setUniqueUserCookie( value = '' )
{
    if( !value || ( value && value == "" ) ) return;

    // Crea un Cookie para identificar al usuario del widget,
    // el cookie tendra una duración de un año, podra se consultado en todas las sub rutas del dominio.
    Cookie.set( nameUniqueUserCookie, value, { expires: 365, path: '/', sameSite: 'strict' } );

    // Asigna el valor de la cookie en una variable para un acceso rapido.
    // uniqueUserCookie = value;
    userClientToken = value;
}

// Fin - Cookies para identificar al usuario.
//////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////
// Modal para Imagen del Widget
const modalImgWidgetChatbot = document.getElementById("modal_image_from_widget_chatbot");

function initModalImgWA()
{
    if( !modalImgWidgetChatbot ) return;

    document.body.append( modalImgWidgetChatbot );

    const closeButton = modalImgWidgetChatbot.querySelector( '.close' );

    if( !closeButton ) return;

    // When the user clicks on <span> (x), close the modal
    closeButton.addEventListener( 'click', () => {

        // closeButton.style.display = 'none';
        toggleShowModalImgWC( false );

    });
}

function toggleShowModalImgWC( show = false, imgUrl = null )
{
    if( !modalImgWidgetChatbot ) return;

    // Get the image and insert it inside the modal - use its "alt" text as a caption
    const modalImg = modalImgWidgetChatbot.querySelector("#img_modal_wc");

    if( !modalImg ) return;

    modalImgWidgetChatbot.style.display = show ? "block" : "none";
    modalImg.src = show ? imgUrl : '';
}


// Fin - Modal para Imagen del Widget
//////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////
// Inicio - Modal Aviso para tocar para la pantalla para habilitar audio.
// Contenedor con Gifs para indicar al usuario que toque la pantalla para reproducir el audio.
const containerGifsToTapTheScreen = document.getElementById('container-audio-toch-screen-widget-agents'); 
let setInteralIconsAnimationInContainerToTapScreen = null;

// Se agrega evento click en el boton e interfaz del widget para registrar la interacción del usuario.
// widgetAccessContact.addEventListener( 'click', () => {
//     registerInteractionUserInWidgetAccessContacts( true );
    
//     removeContainerAdviseForTapTheScreenInWidgetAgent();
// });
// openButtonWidgetAccessContact.addEventListener( 'click', () => registerInteractionUserInWidgetAccessContacts( true ) );

function initModalAdviseForTapTheScreen()
{
    if( activeVoice === 0 /* || ( activeVoice == 1 && firstActionUser == true ) */ ) return 0;

    if( !containerGifsToTapTheScreen ) return false;

    // Muestra el modal con el aviso y los iconos sobre el touch screen.
    containerGifsToTapTheScreen.style.display = "";

    changeIconsFromContainerAdviseForTapTheScreenInWidgetAgent();

    return true;
}

/**
 * Elimina el elmento que mostra en pantalla dos Gifs para indicar que toquen la pantalla para reproducir el audio del Widget.
 */
function removeContainerAdviseForTapTheScreenInWidgetAgent( withAnimation = true )
{
    if( containerGifsToTapTheScreen )
    {
        if( withAnimation )
        {
            containerGifsToTapTheScreen.classList.add( 'animate__fadeOutLeft' );
    
            containerGifsToTapTheScreen.addEventListener('animationend', () => {
                containerGifsToTapTheScreen.remove();
            });
        }
        else
        containerGifsToTapTheScreen.remove();

        if( setInteralIconsAnimationInContainerToTapScreen != null )
        clearInterval( setInteralIconsAnimationInContainerToTapScreen );
    }
}

/**
 * Animaciones para los iconos del container que indican al usuario que interactue tocando la pantalla.
 * @returns 
 */
function changeIconsFromContainerAdviseForTapTheScreenInWidgetAgent()
{
    if( !containerGifsToTapTheScreen ) return;

    const iconsData = { 
        sound:  {
            id: "icon-1-container-audio-toch-screen-widget-agents",
            class: [
                "bi bi-volume-off-fill",
                "bi bi-volume-down-fill",
                "bi bi-volume-up-fill"
            ],
            counter: 0
        },
        hand:  {
            id: "icon-2-container-audio-toch-screen-widget-agents",
            class: [
                "bi bi-hand-index-fill",
                "bi bi-hand-index-thumb-fill"
            ],
            counter: 0
        }
    };

    const changeIconInConatiner = ( element ) => {
        const iconContainer = document.getElementById( element.id );
    
        if( iconContainer )
        {
            iconContainer.className = element.class[ element.counter++ ];
            
            if( element.counter >= element.class.length  ) 
            element.counter = 0;
        }
    };

    setInteralIconsAnimationInContainerToTapScreen = setInterval( () => {

        changeIconInConatiner( iconsData.sound );

        changeIconInConatiner( iconsData.hand );

    }, 1000);
}

// Fin - Modal Aviso para tocar para la pantalla para habilitar audio.
//////////////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////////////
// Evento para mostrar el chat con un Agente
function initEventToShowChatWithAgent()
{
    window.document.addEventListener( "showChatWithAgentFromWidgetAgents", ( e ) => {
        // console.log( "hola desde script agents", e, e.detail );

        if( e.detail.agent && e.detail.agent.agentId && e.detail.customer && ( e.detail.customer.name != "" ) && e.detail.messageToCustomer != "" )
        searchAgentAndShowChatFromEvent( e.detail.agent, e.detail.customer, e.detail.messageToCustomer );
    } )
}

function searchAgentAndShowChatFromEvent( agentInfo, customerInfo, messageToCustomer )
{
    if( !boolUseDesign2 ) return;

    const widgetAgents = document.getElementById('busswe-widget');

    if( !widgetAgents ) return;

    // Button Open Widget
    const buttonWidget = document.querySelector("#busswe-widget aside details");

    if( !buttonWidget ) return;

    // Comrpueba si el widget agentes esta oculto para mostrarlo.
    if( buttonWidget && buttonWidget.open == false )
        buttonWidget.open = true;

    // Oculta el chat si esta abierto
    // const buttonBack = document.querySelector('#busswe-widget #dialog-widget-2 .container-main-chat .header-main-chat .group-options-1 .back-to-list-agents');
    const buttonBack = document.querySelector('#dialog-widget-area-button-back svg');

    if( buttonBack )
    buttonBack.dispatchEvent( new Event("click") );
    // buttonBack.click();

    // Encuentra el objeto del agente en el html.
    const itemAgent = widgetAgents.querySelector( `#item-2-agent-${ agentInfo.agentId }` );
                
    // Encuentra el objeto button Chat en el html.
    const itemButtonChat = widgetAgents.querySelector( `.footer-widget .show-chat-button.show-option-to-contact-agent-button` );
    

    // Ejecuta la función para seleccionar el agente del id, para seleccionar el agente y se muestren las opciones,
    // como el boton ir al chat.
    if( itemAgent )
    {
        // initialMessageFromExtWidget = messageToCustomer;
        
        initialMessageFromExtWidget.message = agentInfo.initial_message;
        initialMessageFromExtWidget.voice = agentInfo.initial_message_voice;

        itemAgent.click();

        itemButtonChat.click();
    }
}


// Fin - Evento para mostrar el chat con un Agente
//////////////////////////////////////////////////////////////////////////////////